/* __next_internal_client_entry_do_not_use__ useRequestQueue auto */ import { queueSchema } from "../../../lib";
import { useRequest } from "../../../request";
export const useRequestQueue = function() {
    let { ...props } = arguments.length > 0 && arguments[0] !== void 0 ? arguments[0] : {};
    return {
        requestPut: useRequest({
            ...props,
            fnRequestProps: (data)=>({
                    body: JSON.stringify(data),
                    method: "PUT",
                    schema: queueSchema,
                    urlBackendPath: "/queue"
                })
        })
    };
};
