import { differenceInHours } from "__barrel_optimize__?names=differenceInHours!=!date-fns";
import { isFamilyPlan } from "./subscription";
export const dueToday = (cart)=>{
    const trialDaysNum = trialDays(cart);
    // If we have an intro offer and trial, today you only pay
    // for the intro offer
    if (trialDaysNum > 0 && cart.intro_offer) {
        var _cart_intro_offer;
        return (_cart_intro_offer = cart.intro_offer) === null || _cart_intro_offer === void 0 ? void 0 : _cart_intro_offer.amount;
    }
    // If its a family plan, you will always be paying proration
    if (isFamilyPlan("product", cart.product) && cart.discount > 0) {
        return cart.total;
    }
    // If no trial, today the cart total is due
    if (trialDaysNum <= 0) {
        return cart.total;
    }
    // Otherwise nothing is due right now
    return 0;
};
export const grant = function(cart, name) {
    let filter = arguments.length > 2 && arguments[2] !== void 0 ? arguments[2] : ()=>true;
    var _cart_promo_code_grants_find, _cart_promo_code;
    return (_cart_promo_code = cart.promo_code) === null || _cart_promo_code === void 0 ? void 0 : (_cart_promo_code_grants_find = _cart_promo_code.grants.find((grant)=>grant.name === name && filter(grant.variables))) === null || _cart_promo_code_grants_find === void 0 ? void 0 : _cart_promo_code_grants_find.variables;
};
export const rule = (cart, name)=>{
    var _cart_promo_code_rules_find, _cart_promo_code;
    return (_cart_promo_code = cart.promo_code) === null || _cart_promo_code === void 0 ? void 0 : (_cart_promo_code_rules_find = _cart_promo_code.rules.find((rule)=>rule.name === name)) === null || _cart_promo_code_rules_find === void 0 ? void 0 : _cart_promo_code_rules_find.variables;
};
export const onlyNewUsers = (cart)=>{
    return !!rule(cart, "had_previous_trial_rule");
};
export const trialDays = (cart)=>{
    var _grant;
    var _grant_trial_days;
    // If we have a grant, use that otherwise calculate
    return (_grant_trial_days = (_grant = grant(cart, "trial_days_grant")) === null || _grant === void 0 ? void 0 : _grant.trial_days) !== null && _grant_trial_days !== void 0 ? _grant_trial_days : Math.round(differenceInHours(new Date(cart.trial_end_date) || new Date(), new Date()) / 24) | 0;
};
export const discountPercent = (cart)=>{
    var _grant;
    var _grant_amount;
    return parseInt((_grant_amount = (_grant = grant(cart, "discount_grant", (item)=>item.type === "percent")) === null || _grant === void 0 ? void 0 : _grant.amount) !== null && _grant_amount !== void 0 ? _grant_amount : 0);
};
export const hasTrialDaysGrant = (cart)=>{
    return !!grant(cart, "trial_days_grant");
};
export const hasSpecifiedEndDate = (cart)=>{
    var _grant;
    return !!((_grant = grant(cart, "trial_end_date_grant")) === null || _grant === void 0 ? void 0 : _grant.end_date);
};
