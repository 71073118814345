import { z } from "zod";
import { consoleErrorPackagesSdk } from "./consoleErrorPackagesSdk";
export const validatePromiseSchema = (param)=>{
    let { promise, schema = z.any() } = param;
    return "production" === "production" ? Promise.resolve(promise).then((data)=>{
        const safeParse = schema.safeParse(data);
        if (safeParse.error) {
            consoleErrorPackagesSdk({
                error: safeParse.error.toString(),
                heading: "".concat(safeParse.error.name, ": ").concat(safeParse.error.toString())
            });
        }
        var _safeParse_data;
        // in production, we give back the data even if it doesn't match
        return (_safeParse_data = safeParse.data) !== null && _safeParse_data !== void 0 ? _safeParse_data : data;
    }) : Promise.resolve(promise).then((data)=>schema.parse(data)).catch((error)=>{
        consoleErrorPackagesSdk({
            error,
            heading: "Failed schema validation"
        });
        throw error;
    });
};
