import { z } from "zod";
import { referenceTypeEnum, sectionItemTypeEnum } from "../../enums";
import { sectionItemChallengeSchema, sectionItemCollectionSchema } from "../collection";
import { deeplinkSchema } from "../deeplinkSchema";
import { sectionItemPrayerSchema } from "../prayer";
import { radioStationSchema } from "../radio-station";
import { dailyQuoteSchema } from "./dailyQuoteSchema";
import { triviaGameSchema } from "./triviaSchema";
export const baseSectionItemSchema = z.object({
    id: z.number(),
    item_id: z.number(),
    item_type: referenceTypeEnum,
    reference_id: z.number(),
    type: sectionItemTypeEnum
});
const collectionAndSessionSchema = z.object({
    collection: sectionItemCollectionSchema,
    prayer: sectionItemPrayerSchema.nullable(),
    parish: z.any().nullable()
});
export const collectionAndSessionSectionItemSchema = baseSectionItemSchema.extend({
    reference_type: referenceTypeEnum.extract([
        "collection_and_session"
    ]),
    reference: collectionAndSessionSchema
});
export const deeplinkCardSectionItemSchema = baseSectionItemSchema.extend({
    reference_type: referenceTypeEnum.extract([
        "deeplink_card"
    ]),
    reference: deeplinkSchema
});
export const collectionSectionItemSchema = baseSectionItemSchema.extend({
    reference_type: referenceTypeEnum.extract([
        "collection"
    ]),
    reference: sectionItemCollectionSchema
});
export const prayerSectionItemSchema = baseSectionItemSchema.extend({
    reference_type: referenceTypeEnum.extract([
        "prayer"
    ]),
    reference: sectionItemPrayerSchema
});
export const challengeSectionItemSchema = baseSectionItemSchema.extend({
    reference_type: referenceTypeEnum.extract([
        "challenge"
    ]),
    reference: sectionItemChallengeSchema
});
export const hallowPlusBannerSectionItemSchema = baseSectionItemSchema.extend({
    reference_type: referenceTypeEnum.extract([
        "hallow_plus_banner"
    ]),
    reference: z.literal(null)
});
export const moodCheckSectionItemSchema = baseSectionItemSchema.extend({
    reference_type: referenceTypeEnum.extract([
        "moodcheck"
    ]),
    reference: z.literal(null)
});
export const triviaSectionItemSchema = baseSectionItemSchema.extend({
    reference_type: referenceTypeEnum.extract([
        "trivia"
    ]),
    reference: triviaGameSchema
});
export const dailyQuoteSectionItemSchema = baseSectionItemSchema.extend({
    reference_type: referenceTypeEnum.extract([
        "daily_quote"
    ]),
    reference: dailyQuoteSchema
});
export const contentItemSectionItemSchema = z.discriminatedUnion("reference_type", [
    baseSectionItemSchema.extend({
        reference_type: referenceTypeEnum.extract([
            "collection"
        ]),
        reference: sectionItemCollectionSchema
    }),
    baseSectionItemSchema.extend({
        reference_type: referenceTypeEnum.extract([
            "prayer"
        ]),
        reference: sectionItemPrayerSchema
    })
]);
export const sectionItemSchema = z.discriminatedUnion("reference_type", [
    collectionAndSessionSectionItemSchema,
    deeplinkCardSectionItemSchema,
    collectionSectionItemSchema,
    prayerSectionItemSchema,
    challengeSectionItemSchema,
    moodCheckSectionItemSchema,
    hallowPlusBannerSectionItemSchema,
    triviaSectionItemSchema,
    dailyQuoteSectionItemSchema,
    baseSectionItemSchema.extend({
        reference_type: referenceTypeEnum.extract([
            "radio_station"
        ]),
        reference: radioStationSchema
    }),
    // TODO: Change these from any to actual types once we start implementing these features in webv2
    baseSectionItemSchema.extend({
        reference_type: referenceTypeEnum.extract([
            "suggested_routine",
            "prayer_goal",
            "daily",
            "create_group",
            "invite_to_group",
            "alerts",
            "campaign"
        ]),
        reference: z.any()
    })
]);
