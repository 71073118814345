import { determineClientOrServer } from "../../utils";
export const getI18nRequestHeaders = async (param)=>{
    let { valueLocale } = param;
    if (valueLocale) return {
        "X-Hallow-Language": valueLocale
    };
    if (determineClientOrServer() === "server") {
        const { getLocale } = await import("@packages/i18n/server");
        return {
            "X-Hallow-Language": await getLocale()
        };
    }
};
