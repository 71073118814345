import { z } from "zod";
export const rawLiteralsSchema = function(arg1, arg2) {
    for(var _len = arguments.length, args = new Array(_len > 2 ? _len - 2 : 0), _key = 2; _key < _len; _key++){
        args[_key - 2] = arguments[_key];
    }
    return z.object({
        raw: z.union([
            arg1,
            arg2,
            ...args
        ])
    });
};
