/* __next_internal_client_entry_do_not_use__ useRequestPages auto */ import { pagesSchema } from "../../../lib";
import { useRequestQuery } from "../../../request";
import { keyPages } from "./keyPages";
export const useRequestPages = (param)=>{
    let { page, ...props } = param;
    return {
        query: useRequestQuery({
            ...props,
            queryFnRequestProps: {
                schema: pagesSchema,
                urlBackendPath: "/pages/".concat(page)
            },
            queryKey: keyPages({
                page
            })
        })
    };
};
