import { z } from "zod";
import { collectionSchema } from "./collection";
import { basePrayerSchema } from "./prayer";
var RoutineItemReferenceType;
(function(RoutineItemReferenceType) {
    RoutineItemReferenceType["COLLECTION"] = "collection";
    RoutineItemReferenceType["CHAPTER"] = "chapter";
    RoutineItemReferenceType["PRAYER"] = "prayer";
    RoutineItemReferenceType["CHALLENGE"] = "challenge";
    RoutineItemReferenceType["DAILY"] = "daily";
    RoutineItemReferenceType["REMINDER"] = "reminder";
})(RoutineItemReferenceType || (RoutineItemReferenceType = {}));
const routineReferenceSchema = z.object({
    collection: collectionSchema.pick({
        desc: true,
        has_access: true,
        has_high_res_illo: true,
        id: true,
        is_album: true,
        is_completed: true,
        paid: true,
        prayers_count: true,
        sessions: true,
        short_desc: true,
        style_id: true,
        supertitle: true,
        title: true
    }),
    collection_id: z.number(),
    id: z.number(),
    show_daily_prayers: z.boolean()
});
const routineSessionSchema = z.object({
    id: z.number(),
    is_completed: z.boolean(),
    prayer: basePrayerSchema.pick({
        collection_id: true,
        detailed_desc: true,
        has_access: true,
        id: true,
        is_available: true,
        is_completed: true,
        is_download_enabled: true,
        is_favorite: true,
        is_redeemed: true,
        is_song: true,
        label_desc: true,
        lengths: true,
        shareable_quote: true,
        shareable_url: true,
        supertitle: true,
        title: true,
        order: true,
        prompt: true
    }),
    prayer_id: z.number(),
    routine_item_id: z.number(),
    timestamp: z.string()
});
export const routineItemSchema = z.object({
    id: z.number(),
    index: z.number(),
    reference: routineReferenceSchema.nullable(),
    reference_id: z.number(),
    reference_type: z.nativeEnum(RoutineItemReferenceType),
    session: routineSessionSchema.nullable()
});
