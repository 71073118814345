import { z } from "zod";
import { priceSchema } from "./priceSchema";
export const periodSchema = z.object({
    number: z.number(),
    unit: z.string()
});
export const productSchema = z.object({
    id: z.number(),
    external_id: z.string(),
    trial_days: z.number(),
    name: z.string(),
    description: z.string().nullable(),
    billing_period: periodSchema,
    price: priceSchema
});
export const productsSchema = z.array(productSchema);
