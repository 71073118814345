import { useLocale } from "@packages/i18n";
import { useQueryClient } from "@tanstack/react-query";
import { useRequestMutation } from "../../../../request";
import { keyFavorites } from "../../favorites";
import { keyCollection } from "../keyCollection";
export const useRequestCollectionFavorite = (param)=>{
    let { id, ...props } = param;
    const locale = useLocale();
    const queryClient = useQueryClient();
    return {
        mutationPost: useRequestMutation({
            ...props,
            mutationFnRequestProps: {
                urlBackendPath: "/collections/".concat(id, "/favorite")
            },
            onSuccess: ()=>{
                queryClient.invalidateQueries({
                    queryKey: [
                        keyCollection({
                            id
                        }),
                        locale
                    ]
                });
                queryClient.invalidateQueries({
                    queryKey: [
                        keyFavorites({
                            displayType: "collections"
                        }),
                        locale
                    ]
                });
            }
        })
    };
};
