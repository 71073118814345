/* __next_internal_client_entry_do_not_use__ useRequestSubscription auto */ import { cancelSubscriptionSchema, refundPaymentSchema, removeNullKeys, subscriptionDetailSchema, subscriptionSchema } from "../../../lib";
import { useRequestMutation, useRequestQuery } from "../../../request";
import { keySubscription, keySubscriptions } from "./keySubscriptions";
export const useRequestSubscription = ()=>{
    return {
        query: useRequestQuery({
            queryFnRequestProps: {
                schema: subscriptionDetailSchema,
                urlBackendPath: "/subscription"
            },
            queryKey: keySubscription(),
            retryOnMount: false,
            retry: 0
        }),
        queryAll: useRequestQuery({
            queryFnRequestProps: {
                schema: subscriptionDetailSchema.array(),
                urlBackendPath: "/subscriptions"
            },
            queryKey: keySubscriptions()
        }),
        mutationSubscribe: useRequestMutation({
            mutationFnRequestProps: (data)=>({
                    body: JSON.stringify({
                        payment_method: data.paymentMethod,
                        payment_platform: data.paymentPlatform,
                        price_id: data.priceId,
                        promo_code: data.promoCode,
                        email: data.email,
                        address: data.address ? removeNullKeys(data.address) : undefined
                    }),
                    method: "POST",
                    schema: subscriptionSchema,
                    urlBackendPath: "/subscription"
                })
        }),
        mutationPaymentInfo: useRequestMutation({
            mutationFnRequestProps: (data)=>({
                    body: JSON.stringify(data),
                    method: "PUT",
                    urlBackendPath: "/subscription/payment"
                })
        }),
        mutationChangePlan: useRequestMutation({
            mutationFnRequestProps: (data)=>({
                    body: JSON.stringify(data),
                    method: "PUT",
                    schema: subscriptionSchema,
                    urlBackendPath: "/subscription"
                })
        }),
        mutationUpgradeMobile: useRequestMutation({
            mutationFnRequestProps: (data)=>({
                    body: JSON.stringify({
                        payment_method: data.paymentMethod,
                        payment_platform: data.paymentPlatform,
                        price_id: data.priceId,
                        promo_code: data.promoCode,
                        email: data.email,
                        address: data.address ? removeNullKeys(data.address) : undefined
                    }),
                    method: "PUT",
                    schema: subscriptionSchema,
                    urlBackendPath: "/subscription/iap-upgrade"
                })
        }),
        mutationCancel: useRequestMutation({
            mutationFnRequestProps: (data)=>({
                    body: JSON.stringify(data),
                    method: "PUT",
                    schema: cancelSubscriptionSchema,
                    urlBackendPath: "/subscription/cancel"
                })
        }),
        mutationRefund: useRequestMutation({
            mutationFnRequestProps: (data)=>({
                    body: JSON.stringify(data),
                    method: "PUT",
                    schema: refundPaymentSchema,
                    urlBackendPath: "/subscription/refund"
                })
        }),
        mutationRestart: useRequestMutation({
            mutationFnRequestProps: (data)=>({
                    body: JSON.stringify(data),
                    method: "PUT",
                    schema: subscriptionSchema,
                    urlBackendPath: "/subscription/restart"
                })
        })
    };
};
