import { z } from "zod";
import { deeplinkSchema } from "./deeplinkSchema";
export const dailyReadingsSchema = z.object({
    liturgical_id: z.string(),
    lectionary_name: z.string().nullable(),
    lectionary_description: z.string().nullable(),
    html: z.string(),
    top_content_deeplink: deeplinkSchema.nullable(),
    bottom_content_deeplink: deeplinkSchema.nullable()
});
