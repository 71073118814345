export const signCFUrl = (url, cookies)=>{
    let connector = "?";
    if (url.includes(connector)) connector = "&";
    const params = [];
    if (!(cookies === null || cookies === void 0 ? void 0 : cookies.length)) return url;
    const cookieSet = cookies[0];
    const { "CloudFront-Policy": policy, "CloudFront-Key-Pair-Id": pair, "CloudFront-Signature": signature } = cookieSet;
    //const expiration = Date.now() + 86000; // 400 less to allow for space between retrieval and signing
    params.push("Policy=".concat(policy));
    params.push("Signature=".concat(signature));
    params.push("Key-Pair-Id=".concat(pair));
    return "".concat(url).concat(connector).concat(params.join("&"));
};
