import { z } from "zod";
const familyUserSchema = z.object({
    id: z.number(),
    name: z.string(),
    last_name: z.string().nullable().optional(),
    email: z.string().nullable(),
    phone: z.string().nullable().optional(),
    has_password: z.boolean()
});
const familyInviteSchema = z.object({
    id: z.number(),
    invitable_type: z.string(),
    invitable_id: z.number(),
    email: z.string(),
    code: z.string(),
    expiration: z.string().datetime()
});
export const familySchema = z.object({
    id: z.number(),
    owner_id: z.number(),
    users: z.array(familyUserSchema),
    invites: z.array(familyInviteSchema),
    is_owner: z.boolean()
});
