import { HEADERS } from "../../../constants";
import { request } from "../../../request";
export const requestSessionLogout = (param)=>{
    let { headers = {} } = param;
    return request({
        headers: {
            ...HEADERS,
            ...headers
        },
        method: "POST",
        urlBackendPath: "/me/logout"
    });
};
