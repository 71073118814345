import { trim } from "./trim";
export const pathMatchFn = (href, exact, pathname, locale)=>{
    if (typeof href === "string") {
        if (!href.startsWith("/")) href = "/".concat(href);
    } else {
        href = trim(href.toString(), "/");
    }
    const closer = exact ? "$" : "";
    const re = new RegExp("^(?:/".concat(locale, ")?").concat(href, "/?").concat(closer));
    return re.test(pathname);
};
