export const keyCommunityMember = (param)=>{
    let { communityId, userId } = param;
    return [
        "communities-member",
        {
            communityId,
            userId
        }
    ];
};
