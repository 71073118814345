import { trustedCollectionSchema } from "../../../lib";
import { request } from "../../../request";
export const requestCollectionTrusted = (param)=>{
    let { id, type = "collection" } = param;
    return request({
        next: {
            tags: [
                "collection",
                "collection-".concat(id)
            ],
            revalidate: 60 * 60
        },
        requestType: "trusted",
        schema: trustedCollectionSchema,
        urlBackendPath: "/trusted/collections/".concat(id).concat(type !== "collection" ? "?type=".concat(type) : "")
    });
};
