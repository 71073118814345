/* __next_internal_client_entry_do_not_use__ useRequestPrayer auto */ import { prayerSchema } from "../../../lib";
import { useRequestQuery } from "../../../request";
import { keyPrayer } from "./keyPrayer";
export const useRequestPrayer = (param)=>{
    let { id, ...props } = param;
    return {
        query: useRequestQuery({
            enabled: typeof id === "number",
            ...props,
            queryFnRequestProps: {
                schema: prayerSchema,
                urlBackendPath: "/prayers/".concat(id)
            },
            queryKey: keyPrayer({
                id
            })
        })
    };
};
