import { sub } from "__barrel_optimize__?names=sub!=!date-fns";
export const getDateByRange = (param)=>{
    let { range } = param;
    const date = new Date().setMinutes(0, 0);
    switch(range){
        case "monthly":
            return sub(date, {
                days: 30
            });
        case "quarterly":
            return sub(date, {
                days: 90
            });
        case "weekly":
            return sub(date, {
                days: 7
            });
        case "yearly":
        default:
            return sub(date, {
                days: 365
            });
    }
};
