import { z } from "zod";
export var FlaggableType;
(function(FlaggableType) {
    FlaggableType["Campaign"] = "campaign";
    FlaggableType["Community"] = "community";
    FlaggableType["Intention"] = "intention";
    FlaggableType["Post"] = "post";
    FlaggableType["PromptResponse"] = "prompt_response";
    FlaggableType["User"] = "user";
})(FlaggableType || (FlaggableType = {}));
export const flaggableTypeEnum = z.nativeEnum(FlaggableType);
