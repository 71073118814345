import { useLocale } from "@packages/i18n";
import { useQueryClient } from "@tanstack/react-query";
import { useRequestMutation } from "../../../request";
import { keyCollection } from "../collection";
import { keyHome } from "../home";
import { keyNextUp } from "../nextUp";
export const useRequestChallenge = (param)=>{
    let { id } = param;
    const urlBackendPath = "/collections/".concat(id, "/users");
    const locale = useLocale();
    const queryClient = useQueryClient();
    const invalidateQueries = ()=>{
        queryClient.invalidateQueries({
            queryKey: [
                keyHome(),
                locale
            ]
        });
        queryClient.invalidateQueries({
            queryKey: [
                keyNextUp(),
                locale
            ]
        });
        queryClient.invalidateQueries({
            queryKey: [
                keyCollection({
                    id
                }),
                locale
            ]
        });
    };
    return {
        mutationDelete: useRequestMutation({
            mutationFnRequestProps: {
                method: "DELETE",
                urlBackendPath
            },
            onSuccess: ()=>invalidateQueries()
        }),
        mutationPost: useRequestMutation({
            mutationFnRequestProps: {
                urlBackendPath
            },
            onSuccess: ()=>invalidateQueries()
        })
    };
};
