import { z } from "zod";
import { FlaggableType } from "../../enums";
import { basePrayerSchema } from "../prayer";
import { userSchema } from "../user";
const flaggedRecordSchema = z.object({
    reported_by: userSchema,
    subcategory: z.string().nullable(),
    reason: z.string().nullable(),
    comment: z.string().nullable(),
    created_at: z.string().datetime()
});
const flaggedIntentionSchema = z.object({
    created_at: z.string().datetime(),
    post_id: z.number(),
    text: z.string(),
    user: userSchema,
    gradient_id: z.number()
});
const flaggedPromptResponseSchema = z.object({
    created_at: z.string().datetime(),
    post_id: z.number(),
    text: z.string(),
    prayer: basePrayerSchema,
    user: userSchema
});
const baseFlaggableObjectWithRecordsSchema = z.object({
    flaggable_id: z.number(),
    flaggable_type: z.string(),
    flagged_records: z.array(flaggedRecordSchema)
});
export const flaggableObjectWithRecordsSchema = z.discriminatedUnion("flaggable_type", [
    baseFlaggableObjectWithRecordsSchema.extend({
        flaggable_type: z.literal(FlaggableType.Intention),
        flaggable: flaggedIntentionSchema
    }),
    baseFlaggableObjectWithRecordsSchema.extend({
        flaggable_type: z.literal(FlaggableType.PromptResponse),
        flaggable: flaggedPromptResponseSchema
    })
]);
