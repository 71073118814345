/* __next_internal_client_entry_do_not_use__ useRequestCommunityMember auto */ import { userSchema } from "../../../../lib";
import { useRequestQuery } from "../../../../request";
import { keyCommunityMember } from "./keyCommunityMember";
export const useRequestCommunityMember = (param)=>{
    let { communityId, userId, ...props } = param;
    return {
        query: useRequestQuery({
            ...props,
            queryFnRequestProps: ()=>({
                    schema: userSchema,
                    urlBackendPath: "/communities/".concat(communityId, "/members/").concat(userId)
                }),
            queryKey: keyCommunityMember({
                communityId,
                userId
            })
        })
    };
};
