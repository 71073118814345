/* __next_internal_client_entry_do_not_use__ useRequestCart auto */ import { cartSchema } from "../../../lib/types/schema/subscription/cartSchema";
import { useRequestMutation, useRequestQuery } from "../../../request";
import { keyCart } from "./keyCart";
export const useRequestCart = (param)=>{
    let { priceId, promoCode } = param;
    return {
        query: useRequestQuery({
            enabled: typeof priceId === "string",
            queryFnRequestProps: {
                schema: cartSchema,
                urlBackendPath: "/cart?price_id=".concat(priceId !== null && priceId !== void 0 ? priceId : "").concat(promoCode ? "&promo_code=".concat(promoCode) : "")
            },
            queryKey: keyCart()
        }),
        checkCart: useRequestMutation({
            mutationFnRequestProps: (param)=>{
                let { promoCode } = param;
                return {
                    schema: cartSchema,
                    urlBackendPath: "/cart?price_id=".concat(priceId !== null && priceId !== void 0 ? priceId : "").concat(promoCode ? "&promo_code=".concat(promoCode) : ""),
                    method: "GET"
                };
            }
        })
    };
};
