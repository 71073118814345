import { setCookieHasRefreshToken } from "../../../lib";
import { useRequestMutation } from "../../../request";
import { useSession } from "../../../session";
import { authSchema, booleanSuccessSchema } from "../auth/types";
export const useRequestAuth = ()=>{
    const { setStatus, setToken } = useSession();
    const onSuccess = (data)=>{
        setCookieHasRefreshToken({
            value: true
        });
        setToken(data.oauth.access_token);
        setStatus("authenticated");
        return new Promise((r)=>setTimeout(r, 10));
    };
    return {
        mutationEmailExists: useRequestMutation({
            mutationFnRequestProps: (data)=>({
                    body: JSON.stringify(data),
                    method: "POST",
                    urlBackendPath: "/exists",
                    schema: booleanSuccessSchema,
                    requestType: "default"
                })
        }),
        mutationLogin: useRequestMutation({
            mutationFnRequestProps: (data)=>({
                    body: JSON.stringify(data),
                    method: "POST",
                    urlBackendPath: "/login",
                    schema: authSchema,
                    requestType: "default"
                }),
            onSuccess
        }),
        mutationRegister: useRequestMutation({
            mutationFnRequestProps: (data)=>({
                    body: JSON.stringify(data),
                    method: "POST",
                    urlBackendPath: "/register",
                    schema: authSchema,
                    requestType: "default"
                }),
            onSuccess
        }),
        mutationPhoneLogin: useRequestMutation({
            mutationFnRequestProps: (data)=>({
                    body: JSON.stringify(data),
                    method: "POST",
                    urlBackendPath: "/login/phone",
                    schema: authSchema,
                    requestType: "default"
                }),
            onSuccess
        }),
        mutationGoogleAuth: useRequestMutation({
            mutationFnRequestProps: (data)=>({
                    body: JSON.stringify(data),
                    method: "POST",
                    urlBackendPath: "/social/google",
                    schema: authSchema,
                    requestType: "default"
                }),
            onSuccess
        }),
        mutationAppleAuth: useRequestMutation({
            mutationFnRequestProps: (data)=>({
                    body: JSON.stringify(data),
                    method: "POST",
                    urlBackendPath: "/social/apple",
                    schema: authSchema,
                    requestType: "default"
                }),
            onSuccess
        }),
        mutationForgotPassword: useRequestMutation({
            mutationFnRequestProps: (data)=>({
                    body: JSON.stringify(data),
                    method: "POST",
                    urlBackendPath: "/password/forgot",
                    schema: booleanSuccessSchema,
                    requestType: "default"
                })
        })
    };
};
