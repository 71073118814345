import { z } from "zod";
import { baseCollectionSchema } from "../../collection";
import { basePrayerSchema } from "../../prayer";
export const baseCommunitiesAdminMostPopularSchema = z.object({
    results: basePrayerSchema.array(),
    total: z.number()
});
export const communitiesAdminMostPopularSchema = z.union([
    baseCommunitiesAdminMostPopularSchema.extend({
        results: baseCollectionSchema.array()
    }),
    baseCommunitiesAdminMostPopularSchema.extend({
        results: basePrayerSchema.array()
    })
]);
