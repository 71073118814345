export var HallowCookies;
(function(HallowCookies) {
    HallowCookies["ANON_SEGMENT_ID"] = "ajs_anonymous_id";
    HallowCookies["API_ENDPOINT"] = "hlwapi";
    HallowCookies["CONSENT"] = "hlwconsent";
    HallowCookies["LD_GUEST_KEY"] = "hlwldguestkey";
    HallowCookies["REFRESH"] = "hlwrfh";
    HallowCookies["SESSION"] = "hlwsess";
    HallowCookies["TIMEZONE"] = "hlwtz";
})(HallowCookies || (HallowCookies = {}));
export default HallowCookies;
