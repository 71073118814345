import { trustedPrayerSchema } from "../../../lib";
import { request } from "../../../request";
export const requestPrayerTrusted = (param)=>{
    let { id } = param;
    return request({
        next: {
            tags: [
                "prayer",
                "prayer-".concat(id)
            ],
            revalidate: 60 * 60
        },
        requestType: "trusted",
        schema: trustedPrayerSchema,
        urlBackendPath: "/trusted/prayers/".concat(id)
    });
};
