import { z } from "zod";
export const getOptionsWithFallback = (baseOptions, currentValue)=>{
    if (!currentValue) return baseOptions;
    const valueExists = baseOptions.some((opt)=>opt.value === currentValue);
    if (valueExists) return baseOptions;
    return [
        ...baseOptions,
        {
            value: currentValue,
            label: currentValue
        }
    ];
};
export const zodRequiredString = (message)=>z.string({
        required_error: message
    }).min(1, {
        message
    });
