import { z } from "zod";
import { deleteCookie, getCookie, setCookie } from "./utils";
export const cookieHlwConsentSchema = z.number().array().nullable();
export const COOKIE_KEY_HLW_CONSENT = "hlwconsent";
export const deleteCookieHlwConsent = function() {
    let { ...props } = arguments.length > 0 && arguments[0] !== void 0 ? arguments[0] : {};
    return deleteCookie({
        key: COOKIE_KEY_HLW_CONSENT,
        ...props
    });
};
export const getCookieHlwConsent = function() {
    let { ...props } = arguments.length > 0 && arguments[0] !== void 0 ? arguments[0] : {};
    return getCookie({
        key: COOKIE_KEY_HLW_CONSENT,
        schema: cookieHlwConsentSchema,
        ...props
    });
};
export const setCookieHlwConsent = (param)=>{
    let { ...props } = param;
    return setCookie({
        key: COOKIE_KEY_HLW_CONSENT,
        ...props
    });
};
