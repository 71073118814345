/* __next_internal_client_entry_do_not_use__ useRequestFamily auto */ import { useLocale } from "@packages/i18n";
import { useQueryClient } from "@tanstack/react-query";
import { familySchema } from "../../../lib";
import { useRequestMutation, useRequestQuery } from "../../../request";
import { keyFamily } from "./keyFamily";
import { keySubscription } from "./keySubscriptions";
export const useRequestFamily = ()=>{
    const queryClient = useQueryClient();
    const locale = useLocale();
    return {
        query: useRequestQuery({
            queryFnRequestProps: {
                schema: familySchema,
                urlBackendPath: "/family"
            },
            queryKey: keyFamily()
        }),
        mutationAddMember: useRequestMutation({
            mutationFnRequestProps: (data)=>({
                    urlBackendPath: "/family/users",
                    method: "POST",
                    body: JSON.stringify(data)
                })
        }),
        mutationRemoveMember: useRequestMutation({
            mutationFnRequestProps: (param)=>{
                let { id } = param;
                return {
                    urlBackendPath: "/family/users/".concat(id),
                    method: "DELETE"
                };
            },
            onSuccess: ()=>{
                queryClient.invalidateQueries({
                    queryKey: [
                        keySubscription(),
                        locale
                    ]
                });
            }
        }),
        mutationCancelInvite: useRequestMutation({
            mutationFnRequestProps: (param)=>{
                let { id } = param;
                return {
                    urlBackendPath: "/family/invites/".concat(id),
                    method: "DELETE"
                };
            }
        }),
        mutationJoin: useRequestMutation({
            mutationFnRequestProps: (param)=>{
                let { code } = param;
                return {
                    urlBackendPath: "/families/".concat(code, "/users"),
                    method: "POST"
                };
            }
        })
    };
};
