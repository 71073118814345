/* __next_internal_client_entry_do_not_use__ useRequestOnboarding auto */ import { useRequestMutation } from "../../../request";
export const useRequestOnboarding = ()=>({
        submitAnswer: useRequestMutation({
            mutationFnRequestProps: (param)=>{
                let { questionId, answers } = param;
                return {
                    body: JSON.stringify({
                        choices: answers
                    }),
                    urlBackendPath: "/poll/".concat(questionId),
                    method: "POST"
                };
            }
        })
    });
