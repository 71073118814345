import { z } from "zod";
export const queueContentTypeEnum = z.enum([
    "audio",
    "challenge",
    "chapter",
    "collection",
    "prayer",
    "playlist_content",
    "radio_station"
]);
