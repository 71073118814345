/* __next_internal_client_entry_do_not_use__ useRequestCampaignDetails auto */ import { campaignDetailsSchema } from "../../../../lib";
import { useRequestQuery } from "../../../../request";
import { keyCampaignDetails } from "./keyCampaignDetails";
export const useRequestCampaignDetails = (param)=>{
    let { id, ...props } = param;
    return {
        query: useRequestQuery({
            ...props,
            queryFnRequestProps: {
                schema: campaignDetailsSchema,
                urlBackendPath: "/campaign-details/".concat(id)
            },
            queryKey: keyCampaignDetails({
                id
            })
        })
    };
};
