import Cookies from "js-cookie";
import { COOKIE_KEY_HLW_CONSENT } from "../cookieHlwConsent.cookie";
import { deleteCookie } from "./deleteCookie";
export const setCookie = (param)=>{
    let { cookies, key, value, domain = ".hallow.com", options = {} } = param;
    if (value === null) {
        deleteCookie({
            cookies,
            key
        });
        return;
    }
    const cookieOptions = {
        domain,
        path: "/",
        ...options
    };
    /** TODO: remove (Access cannot read JSON.stringify() value...) */ if (key === COOKIE_KEY_HLW_CONSENT) return Cookies.withConverter({
        write: function(value) {
            return "[".concat(value, "]");
        }
    }).set(key, value, cookieOptions);
    const parsedValue = typeof value === "string" ? value : JSON.stringify(value);
    if (cookies) {
        cookies.set(key, parsedValue, cookieOptions);
    } else {
        Cookies.set(key, parsedValue, cookieOptions);
    }
};
