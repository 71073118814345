import { z } from "zod";
export const searchMetaSchema = z.object({
    alerts: z.any().array(),
    warnings: z.any().array(),
    precision: z.number(),
    page: z.object({
        current: z.number(),
        total_pages: z.number(),
        total_results: z.number(),
        size: z.number()
    }),
    engine: z.object({
        name: z.string(),
        type: z.string()
    }),
    request_id: z.string()
});
