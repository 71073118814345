import { useLocale } from "@packages/i18n";
import { useQueryClient } from "@tanstack/react-query";
import { useRequestMutation } from "../../../../request";
import { keyHome, keyNextUp, keyPages } from "../..";
import { keyCampaignDetails } from "../details";
export const useRequestCampaignUsers = (param)=>{
    let { id } = param;
    const urlBackendPath = "/campaigns/".concat(id, "/users");
    const locale = useLocale();
    const queryClient = useQueryClient();
    const invalidateQueries = ()=>{
        queryClient.invalidateQueries({
            queryKey: [
                keyCampaignDetails({
                    id
                }),
                locale
            ]
        });
        queryClient.invalidateQueries({
            queryKey: [
                keyHome(),
                locale
            ]
        });
        queryClient.invalidateQueries({
            queryKey: [
                keyNextUp(),
                locale
            ]
        });
        queryClient.invalidateQueries({
            queryKey: [
                keyPages({
                    page: "campaigns"
                }),
                locale
            ]
        });
    };
    return {
        mutationDelete: useRequestMutation({
            mutationFnRequestProps: {
                method: "DELETE",
                urlBackendPath
            },
            onSuccess: ()=>invalidateQueries()
        }),
        mutationPost: useRequestMutation({
            mutationFnRequestProps: {
                urlBackendPath
            },
            onSuccess: ()=>invalidateQueries()
        })
    };
};
