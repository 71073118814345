const SST_URL_SELF = (defaultValue)=>{
    if (!process.env.SST_STAGE && !process.env.SST_RESOURCE_App) return defaultValue !== null && defaultValue !== void 0 ? defaultValue : "";
    let stage = process.env.SST_STAGE;
    if (!stage) {
        try {
            const json = JSON.parse(process.env.SST_RESOURCE_App);
            stage = json.stage;
        } catch (e) {
            return defaultValue !== null && defaultValue !== void 0 ? defaultValue : "";
        }
    }
    if (stage.startsWith("pr")) {
        return "https://".concat(stage, "-preview.hallow.com");
    }
    return defaultValue !== null && defaultValue !== void 0 ? defaultValue : "";
};
export const URL_SELF = process.env.NEXT_PUBLIC_VERCEL_ENV === "preview" && process.env.NEXT_PUBLIC_VERCEL_GIT_COMMIT_REF !== "dev" ? "https://".concat(process.env.NEXT_PUBLIC_VERCEL_URL) : SST_URL_SELF(process.env.NEXT_PUBLIC_SELF_URL);
