/* __next_internal_client_entry_do_not_use__ useRequestSearch auto */ import { searchResultSchema, URL_SEARCH } from "../../../lib";
import { useRequestInfiniteQuery } from "../../../request";
import { ELASTIC_SEARCH_DIRECTORY, PAGE_SIZE_SEARCH } from "./constants";
import { keySearch } from "./keySearch";
export const useRequestSearch = (param)=>{
    let { filters, search } = param;
    return {
        infiniteQuery: useRequestInfiniteQuery({
            enabledWithoutSessionToken: true,
            getNextPageParam: (lastPage)=>lastPage.meta.page.current < lastPage.meta.page.total_pages ? lastPage.meta.page.current + 1 : null,
            initialPageParam: 1,
            queryFnRequestProps: (param)=>{
                let { pageParam } = param;
                return {
                    body: JSON.stringify({
                        filters: {
                            all: [
                                {
                                    languages: [
                                        filters.locale
                                    ]
                                },
                                {
                                    type: filters.type ? [
                                        filters.type
                                    ] : [
                                        "page",
                                        "collection",
                                        "prayer",
                                        "radio_station",
                                        "setting",
                                        "navigation"
                                    ]
                                }
                            ]
                        },
                        page: {
                            current: pageParam,
                            size: PAGE_SIZE_SEARCH
                        },
                        query: search
                    }),
                    method: "POST",
                    requestType: "search",
                    schema: searchResultSchema,
                    url: "".concat(URL_SEARCH, "/").concat(ELASTIC_SEARCH_DIRECTORY, "/search")
                };
            },
            queryKey: keySearch({
                filters,
                search
            })
        })
    };
};
