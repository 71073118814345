import { z } from "zod";
export const decodedTokenSchema = z.object({
    _id: z.number(),
    consent: z.number().array(),
    device: z.number(),
    email: z.string(),
    exp: z.number(),
    id: z.number(),
    name: z.string(),
    permissions: z.number().array(),
    platform: z.string()
});
