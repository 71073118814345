import { homeSchema } from "../../../lib";
import { useRequestQuery } from "../../../request";
import { keyHome } from "./keyHome";
export const useRequestHome = function() {
    let { ...props } = arguments.length > 0 && arguments[0] !== void 0 ? arguments[0] : {};
    return {
        query: useRequestQuery({
            ...props,
            queryFnRequestProps: {
                schema: homeSchema,
                urlBackendPath: "/home"
            },
            queryKey: keyHome()
        })
    };
};
