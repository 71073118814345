import { z } from "zod";
import { communityUserRelationshipStateEnum } from "../../enums";
export const userSchema = z.object({
    id: z.number(),
    name: z.string(),
    last_name: z.string().nullable().optional(),
    username: z.string().nullable(),
    image_url: z.string(),
    is_admin: z.boolean().nullable().optional(),
    bio: z.string().nullable(),
    relation_description: z.string().nullable(),
    is_self: z.boolean(),
    is_friend: z.boolean(),
    relationship_state: communityUserRelationshipStateEnum
});
