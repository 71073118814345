import { communitySchema } from "../../../../lib";
import { request } from "../../../../request";
export const requestCommunitiesAdminCommunityProps = {
    schema: communitySchema
};
export const requestCommunitiesAdminCommunity = (param)=>{
    let { id, ...props } = param;
    return request({
        ...props,
        urlBackendPath: "/communities/".concat(id),
        ...requestCommunitiesAdminCommunityProps
    });
};
