/* __next_internal_client_entry_do_not_use__ useRequestRoutine auto */ import { routineResponseSchema } from "../../../lib";
import { useRequestQuery } from "../../../request";
import { keyRoutine } from "./keyRoutine";
export const useRequestRoutine = function() {
    let { ...props } = arguments.length > 0 && arguments[0] !== void 0 ? arguments[0] : {};
    return {
        query: useRequestQuery({
            ...props,
            queryFnRequestProps: {
                schema: routineResponseSchema,
                urlBackendPath: "/routine/v2"
            },
            queryKey: keyRoutine()
        })
    };
};
