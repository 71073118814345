/* __next_internal_client_entry_do_not_use__ useDate auto */ import { useTranslations } from "@packages/i18n";
import { differenceInDays, differenceInHours, differenceInMinutes, differenceInSeconds, format } from "__barrel_optimize__?names=differenceInDays,differenceInHours,differenceInMinutes,differenceInSeconds,format!=!date-fns";
export const useDate = ()=>{
    const t = useTranslations();
    const getDateFormatDifferenceNow = (param)=>{
        let { date } = param;
        const now = new Date();
        const dateDifferenceInSeconds = differenceInSeconds(now, date);
        if (dateDifferenceInSeconds < 60) return t("direct_messages_time_ago_just_now");
        if (dateDifferenceInSeconds < 3600) return t("direct_messages_time_ago_minutes", {
            0: differenceInMinutes(now, date)
        });
        if (dateDifferenceInSeconds < 86400) return t("direct_messages_time_ago_hours", {
            0: differenceInHours(now, date)
        });
        if (dateDifferenceInSeconds < 604800) return t("direct_messages_time_ago_days", {
            direct_messages_time_ago_days: differenceInDays(now, date)
        });
        if (dateDifferenceInSeconds < 31536000) return t("direct_messages_time_ago_greater_than_week").replace("d", format(date, "d")).replace("MMM", format(date, "MMM"));
        return t("direct_messages_time_ago_greater_than_year").replace("d", format(date, "d")).replace("MMM", format(date, "MMM")).replace("YYYY", format(date, "y"));
    };
    return {
        getDateFormatDifferenceNow
    };
};
