import { z } from "zod";
import { URL_BACKEND } from "../lib";
import { consoleErrorPackagesSdk, validatePromiseSchema } from "../utils";
import { RequestError } from "./errors";
import { getRequestHeaders } from "./utils";
export const request = async (param)=>{
    let { headers = {}, headersValues, method = "GET", refresh, requestType = "authenticated", retry, schema = z.any(), url, urlBackendPath, ...props } = param;
    return fetch(urlBackendPath ? "".concat(URL_BACKEND).concat(urlBackendPath) : url !== null && url !== void 0 ? url : "", {
        credentials: requestType === "other" ? "omit" : "include",
        method,
        ...props,
        headers: {
            ...await getRequestHeaders({
                requestType,
                values: headersValues
            }),
            ...headers
        }
    }).then((response)=>{
        if (!response.ok) throw response;
        if (response.status === 204) return;
        return validatePromiseSchema({
            promise: response.json(),
            schema
        });
    }).catch(async (error)=>{
        var _this, _error_json;
        if (error.status === 401 && urlBackendPath !== "/oauth/refresh" && refresh && !retry) {
            const { access_token } = await refresh();
            if (access_token) {
                return request({
                    ...props,
                    headersValues: {
                        ...headersValues,
                        token: access_token
                    },
                    urlBackendPath,
                    method,
                    retry: true
                });
            }
        }
        var _error;
        const errorMessage = (_error = (_this = await (error === null || error === void 0 ? void 0 : (_error_json = error.json) === null || _error_json === void 0 ? void 0 : _error_json.call(error))) === null || _this === void 0 ? void 0 : _this.error) !== null && _error !== void 0 ? _error : error;
        consoleErrorPackagesSdk({
            error: errorMessage,
            heading: "Failed request ".concat(method, " ").concat(urlBackendPath ? "".concat(URL_BACKEND).concat(urlBackendPath) : url !== null && url !== void 0 ? url : "")
        });
        throw new RequestError(errorMessage, error.status);
    });
};
