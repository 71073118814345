import { HEADERS, HEADERS_AUTHENTICATED, HEADERS_OTHER, HEADERS_SEARCH, HEADERS_TRUSTED } from "../../constants";
import { getI18nRequestHeaders } from "../../i18n";
import { getSessionRequestHeaders } from "../../session";
export const getRequestHeaders = async (param)=>{
    let { values, requestType } = param;
    let headers = HEADERS;
    if (requestType === "other") return HEADERS_OTHER;
    if (values === null || values === void 0 ? void 0 : values.appSession) headers = {
        ...headers,
        "X-Hallow-App-Session": values === null || values === void 0 ? void 0 : values.appSession
    };
    headers = {
        ...headers,
        ...await getI18nRequestHeaders({
            valueLocale: values === null || values === void 0 ? void 0 : values.locale
        })
    };
    if (requestType === "authenticated") headers = {
        ...headers,
        ...await getSessionRequestHeaders({
            valueToken: values === null || values === void 0 ? void 0 : values.token
        })
    };
    switch(requestType){
        case "authenticated":
            return {
                ...headers,
                ...HEADERS_AUTHENTICATED
            };
        case "search":
            return {
                ...headers,
                ...HEADERS_SEARCH
            };
        case "trusted":
            return {
                ...headers,
                ...HEADERS_TRUSTED
            };
        case "default":
        default:
            return headers;
    }
};
