import { communityChallengeSchema } from "../../../../lib/types/schema/community/challenge";
import { request } from "../../../../request";
export const requestCommunityChallengeTrusted = (param)=>{
    let { id, ...props } = param;
    return request({
        ...props,
        next: {
            tags: [
                "community-challenge",
                "community-challenge-".concat(id)
            ],
            revalidate: 60 * 60
        },
        requestType: "trusted",
        schema: communityChallengeSchema,
        urlBackendPath: "/trusted/community-challenges/".concat(id)
    });
};
