/* __next_internal_client_entry_do_not_use__ useRequestAvatars auto */ import { avatarsSchema } from "../../../lib";
import { useRequestQuery } from "../../../request";
import { keyAvatars } from "./keyAvatars";
export const useRequestAvatars = function() {
    let { ...props } = arguments.length > 0 && arguments[0] !== void 0 ? arguments[0] : {};
    return {
        query: useRequestQuery({
            ...props,
            queryFnRequestProps: {
                schema: avatarsSchema,
                urlBackendPath: "/avatars"
            },
            queryKey: keyAvatars()
        })
    };
};
