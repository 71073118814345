/* __next_internal_client_entry_do_not_use__ useRequestCommunitiesChildren auto */ import { getRequestNextPageParam } from "../../../../lib";
import { communitiesChildrenSchema } from "../../../../lib/types/schema/community/children";
import { useRequestInfiniteQuery } from "../../../../request";
import { keyCommunitiesChildren } from "./keyCommunitiesChildren";
const REQUEST_COMMUNITIES_CHILDREN_PAGE_SIZE = 25;
export const useRequestCommunitiesChildren = (param)=>{
    let { id, type, ...props } = param;
    return {
        infiniteQuery: useRequestInfiniteQuery({
            getNextPageParam: (lastPage, _allPages, lastPageParam)=>getRequestNextPageParam({
                    total: lastPage.total,
                    lastPageParam,
                    pageSize: REQUEST_COMMUNITIES_CHILDREN_PAGE_SIZE
                }),
            initialPageParam: 0,
            ...props,
            queryFnRequestProps: (param)=>{
                let { pageParam } = param;
                return {
                    schema: communitiesChildrenSchema,
                    urlBackendPath: "/communities/".concat(id, "/children?page=").concat(pageParam, "&page_size=").concat(REQUEST_COMMUNITIES_CHILDREN_PAGE_SIZE, "&type=").concat(type)
                };
            },
            queryKey: keyCommunitiesChildren({
                id
            })
        })
    };
};
