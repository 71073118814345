/* __next_internal_client_entry_do_not_use__ useRequestNextUp auto */ import { nextUpSchema } from "../../../lib";
import { useRequestQuery } from "../../../request";
import { keyNextUp } from "./keyNextUp";
export const useRequestNextUp = function() {
    let { ...props } = arguments.length > 0 && arguments[0] !== void 0 ? arguments[0] : {};
    return {
        query: useRequestQuery({
            ...props,
            queryFnRequestProps: {
                schema: nextUpSchema,
                urlBackendPath: "/nextup/v2"
            },
            queryKey: keyNextUp()
        })
    };
};
