import { z } from "zod";
export const emailAuthFlowSchema = z.object({
    email: z.string().email()
});
export const passwordAuthFlowSchema = z.object({
    password: z.string()
});
export const phoneAuthFlowSchema = z.object({
    phone: z.string()
});
