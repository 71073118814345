/* __next_internal_client_entry_do_not_use__ useRequestOtp auto */ import { z } from "zod";
import { useRequestMutation } from "../../../request";
const requestOtpSchema = z.object({
    exists: z.boolean()
});
export const useRequestOtp = ()=>({
        mutationPost: useRequestMutation({
            mutationFnRequestProps: (data)=>({
                    body: JSON.stringify(data),
                    urlBackendPath: "/otp",
                    schema: requestOtpSchema,
                    requestType: "default"
                })
        })
    });
