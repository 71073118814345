import { sectionSchema } from "../../../lib";
import { request } from "../../../request";
export const requestSectionTrusted = (param)=>{
    let { id } = param;
    return request({
        next: {
            tags: [
                "sections",
                "sections-".concat(id)
            ],
            revalidate: 60 * 60
        },
        requestType: "trusted",
        schema: sectionSchema,
        urlBackendPath: "/trusted/sections/".concat(id)
    });
};
