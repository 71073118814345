/* __next_internal_client_entry_do_not_use__ useRequestCollection auto */ import { collectionSchema } from "../../../lib";
import { useRequestQuery } from "../../../request";
import { keyCollection } from "./keyCollection";
export const useRequestCollection = (param)=>{
    let { id, type = "collection", ...props } = param;
    return {
        ...props,
        query: useRequestQuery({
            queryFnRequestProps: {
                schema: collectionSchema,
                urlBackendPath: "/collections/".concat(id).concat(type !== "collection" ? "?type=".concat(type) : "")
            },
            queryKey: keyCollection({
                id
            })
        })
    };
};
