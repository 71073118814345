import { URL_ACCESS } from "../constants";
import { appendQueries } from "./appendQueries";
export const openAppDownloads = async ()=>{
    window.location.href = "https://hallow.com/downloads";
};
export const openAppFavorites = async ()=>{
    window.location.href = "https://hallow.com/favorites";
};
export const redirectToAuth = async function() {
    let flowPath = arguments.length > 0 && arguments[0] !== void 0 ? arguments[0] : "", queries = arguments.length > 1 && arguments[1] !== void 0 ? arguments[1] : {};
    window.location.href = "".concat(URL_ACCESS).concat(flowPath, "?loginRedirect=").concat(encodeURIComponent(window.location.href)).concat(appendQueries(queries));
};
export const redirectToSubscribe = async function() {
    let newWindow = arguments.length > 0 && arguments[0] !== void 0 ? arguments[0] : false;
    const url = "".concat(URL_ACCESS, "/onboarding?page=subscribe&loginRedirect=").concat(encodeURIComponent(window.location.href), "&subscribeRedirect=").concat(encodeURIComponent(window.location.href));
    if (newWindow) {
        window.open(url);
    } else {
        window.location.href = url;
    }
};
export const openEmail = (param)=>{
    let { email, subject = "", body = "" } = param;
    const mailtoLink = "mailto:".concat(encodeURIComponent(email), "?subject=").concat(encodeURIComponent(subject), "&body=").concat(encodeURIComponent(body));
    window.open(mailtoLink, "_blank");
};
