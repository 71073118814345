/* __next_internal_client_entry_do_not_use__ useRequestPromo auto */ import { promoCodeSchema } from "../../../lib/types/schema/subscription/cartSchema";
import { useRequestQuery } from "../../../request";
import { keyPromoCode } from "./keyPromoCode";
export const useRequestPromo = (param)=>{
    let { code } = param;
    return {
        query: useRequestQuery({
            queryFnRequestProps: {
                schema: promoCodeSchema,
                urlBackendPath: "/promocodes/".concat(code)
            },
            queryKey: keyPromoCode()
        })
    };
};
