import { z } from "zod";
import { communityActivityFeedReferenceTypeEnum } from "../../../enums";
import { basePrayerSchema } from "../../prayer";
import { userSchema } from "../../user";
export const baseCommunityActivityFeedResultSchema = z.object({
    created_at: z.string().datetime(),
    id: z.number(),
    reference_id: z.number(),
    source: z.string(),
    user: userSchema
});
export const communityActivityFeedResultIntentionSchema = baseCommunityActivityFeedResultSchema.extend({
    reference_type: communityActivityFeedReferenceTypeEnum.extract([
        "intention"
    ]),
    reference: z.object({
        gradient_id: z.number().optional(),
        text: z.string()
    })
});
export const communityActivityFeedResultPromptResponseSchema = baseCommunityActivityFeedResultSchema.extend({
    reference_type: communityActivityFeedReferenceTypeEnum.extract([
        "prompt_response"
    ]),
    reference: z.object({
        prayer: basePrayerSchema,
        text: z.string(),
        user: userSchema
    })
});
export const communityActivityFeedResultSchema = z.discriminatedUnion("reference_type", [
    baseCommunityActivityFeedResultSchema.extend({
        reference_type: communityActivityFeedReferenceTypeEnum.extract([
            "intention"
        ]),
        reference: z.object({
            gradient_id: z.number().optional(),
            text: z.string()
        })
    }),
    baseCommunityActivityFeedResultSchema.extend({
        reference_type: communityActivityFeedReferenceTypeEnum.extract([
            "prompt_response"
        ]),
        reference: z.object({
            prayer: basePrayerSchema,
            text: z.string(),
            user: userSchema
        })
    })
]);
