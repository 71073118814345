/* __next_internal_client_entry_do_not_use__ useRequestExists auto */ import { z } from "zod";
import { useRequestQuery } from "../../../request";
import { keyExists } from "./keyExists";
export const useRequestExists = (param)=>{
    let { email, enabled, ...props } = param;
    return {
        ...props,
        query: useRequestQuery({
            enabled: enabled && (email === null || email === void 0 ? void 0 : email.length) > 0,
            enabledWithoutSessionToken: true,
            queryFnRequestProps: {
                body: JSON.stringify({
                    email
                }),
                method: "POST",
                requestType: "default",
                schema: z.boolean(),
                urlBackendPath: "/exists"
            },
            queryKey: keyExists({
                email
            })
        })
    };
};
