/* __next_internal_client_entry_do_not_use__ useRequestBackgroundSounds auto */ import { prayerQueueItemSchema } from "../../../lib";
import { useRequestQuery } from "../../../request";
import { keyBackgroundSounds } from "./keyBackgroundSounds";
export const useRequestBackgroundSounds = function() {
    let { ...props } = arguments.length > 0 && arguments[0] !== void 0 ? arguments[0] : {};
    return {
        query: useRequestQuery({
            ...props,
            queryFnRequestProps: {
                schema: prayerQueueItemSchema.array(),
                urlBackendPath: "/background-tracks",
                cache: "force-cache",
                next: {
                    revalidate: 86400,
                    tags: [
                        "background-sounds"
                    ]
                }
            },
            queryKey: keyBackgroundSounds(),
            enabledWithoutSessionToken: false,
            gcTime: 86400000
        })
    };
};
