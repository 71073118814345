/* __next_internal_client_entry_do_not_use__ useRequestQueueSkips auto */ import { queueSkipsSchema } from "../../../../lib";
import { useRequestMutation, useRequestQuery } from "../../../../request";
import { keyQueueSkips } from "./keyQueueSkips";
export const useRequestQueueSkips = function() {
    let { ...props } = arguments.length > 0 && arguments[0] !== void 0 ? arguments[0] : {};
    return {
        mutatePost: useRequestMutation({
            ...props,
            mutationFnRequestProps: ()=>({
                    method: "POST",
                    schema: queueSkipsSchema,
                    urlBackendPath: "/queue/skips"
                })
        }),
        query: useRequestQuery({
            enabledWithoutSessionToken: false,
            queryKey: keyQueueSkips(),
            queryFnRequestProps: {
                schema: queueSkipsSchema,
                urlBackendPath: "/queue/skips"
            }
        })
    };
};
