import { z } from "zod";
// don't simplify this import -- importing directly from /queue causes a circular dependency, because
// exports from that file depend on this current file
import { queueHlsObject } from "./queue/queueHlsObject";
import { trackSchema } from "./trackSchema";
export const playableAudio = trackSchema.omit({
    prayer_id: true
}).extend({
    bg_sounds_enabled: z.boolean(),
    hls: queueHlsObject.nullable(),
    intro_ends_at: z.number().nullable(),
    pauses: z.number().array(),
    resumes_at: z.number().nullable(),
    resumes_session_id: z.string().uuid().nullable(),
    show_music_controls: z.boolean(),
    speed_changes_enabled: z.boolean(),
    url: z.string(),
    prayer_id: z.number().nullable()
});
