import { communitySchema } from "../../../lib";
import { request } from "../../../request";
export const requestCommunityTrusted = (param)=>{
    let { id } = param;
    return request({
        next: {
            tags: [
                "community",
                "community-".concat(id)
            ],
            revalidate: 60 * 60
        },
        requestType: "trusted",
        schema: communitySchema,
        urlBackendPath: "/trusted/communities/".concat(id)
    });
};
