/* __next_internal_client_entry_do_not_use__ useRequestSection auto */ import { sectionSchema } from "../../../lib";
import { useRequestQuery } from "../../../request";
import { keySection } from "./keySection";
export const useRequestSection = (param)=>{
    let { id, ...props } = param;
    return {
        query: useRequestQuery({
            ...props,
            queryFnRequestProps: {
                schema: sectionSchema,
                urlBackendPath: "/sections/".concat(id)
            },
            queryKey: keySection({
                id
            })
        })
    };
};
