/* __next_internal_client_entry_do_not_use__ useRequestInfiniteQuery auto */ import { useInfiniteQuery } from "@tanstack/react-query";
import { useSession } from "../../session";
import { request } from "../request";
import { useHeadersValues } from "./useHeadersValues";
export const useRequestInfiniteQuery = (param)=>{
    let { enabled = true, enabledWithoutSessionToken = true, queryKey, queryFnRequestProps, ...props } = param;
    const headersValues = useHeadersValues();
    const { token, refresh } = useSession();
    return useInfiniteQuery({
        enabled: (enabledWithoutSessionToken ? true : typeof token === "string") && enabled,
        queryFn: (props)=>request({
                ...props,
                headersValues: headersValues.current,
                refresh,
                ...typeof queryFnRequestProps === "function" ? queryFnRequestProps(props) : queryFnRequestProps
            }),
        queryKey: [
            queryKey,
            headersValues.current.locale
        ],
        ...props
    });
};
