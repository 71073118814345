import { userSchema } from "../../../../lib";
import { request } from "../../../../request";
export const getRequestCommunityUserProps = (param)=>{
    let { id } = param;
    return {
        schema: userSchema,
        urlBackendPath: "/community/users/".concat(id)
    };
};
export const requestCommunityUser = (param)=>{
    let { id, ...props } = param;
    return request({
        ...props,
        ...getRequestCommunityUserProps({
            id
        })
    });
};
