import { z } from "zod";
import { guideSchema } from "../guideSchema";
export const radioSongSchema = z.object({
    album_image_url: z.string(),
    album_name: z.string(),
    artist_name: z.string(),
    id: z.number(),
    song_id: z.number(),
    title: z.string(),
    artists: guideSchema.array()
});
