/* __next_internal_client_entry_do_not_use__ useRequestRadioStation auto */ import { radioStationDetailsSchema } from "../../../lib";
import { useRequestQuery } from "../../../request";
import { keyRadioStation } from "./keyRadioStation";
export const useRequestRadioStation = (param)=>{
    let { id, ...props } = param;
    return {
        query: useRequestQuery({
            ...props,
            enabledWithoutSessionToken: false,
            queryKey: keyRadioStation({
                id
            }),
            queryFnRequestProps: {
                schema: radioStationDetailsSchema,
                urlBackendPath: "/radio-station-details/".concat(id)
            }
        })
    };
};
