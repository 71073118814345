/* __next_internal_client_entry_do_not_use__ useLocalStorageState auto */ import { useEffect, useState } from "react";
export const useLocalStorageState = (param)=>{
    let { defaultValue = null, key } = param;
    const windowExists = "object" !== "undefined";
    var _window_localStorage_getItem;
    const [state, setState] = useState(windowExists ? JSON.parse((_window_localStorage_getItem = window.localStorage.getItem(key)) !== null && _window_localStorage_getItem !== void 0 ? _window_localStorage_getItem : JSON.stringify(defaultValue)) : defaultValue);
    useEffect(()=>{
        var _window;
        if (windowExists) (_window = window) === null || _window === void 0 ? void 0 : _window.localStorage.setItem(key, JSON.stringify(state));
    }, [
        state
    ]);
    return [
        state,
        setState
    ];
};
