/* __next_internal_client_entry_do_not_use__ useRequestSessionsCount auto */ import { sessionsCountSchema } from "../../../../lib";
import { useRequestQuery } from "../../../../request";
import { keySessionsCount } from "./keySessionsCount";
export const useRequestSessionsCount = function() {
    let { ...props } = arguments.length > 0 && arguments[0] !== void 0 ? arguments[0] : {};
    return {
        query: useRequestQuery({
            ...props,
            queryFnRequestProps: {
                schema: sessionsCountSchema,
                urlBackendPath: "/sessions/count"
            },
            queryKey: keySessionsCount()
        })
    };
};
