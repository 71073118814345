import { useLocale } from "@packages/i18n";
import { useEffect, useRef } from "react";
import { APP_SESSION_STORAGE_KEY, useLocalStorageState, uuid } from "../../lib";
import { useSession } from "../../session";
export const useHeadersValues = ()=>{
    const [appSession] = useLocalStorageState({
        key: APP_SESSION_STORAGE_KEY,
        defaultValue: uuid()
    });
    const locale = useLocale();
    const { token } = useSession();
    const headersValues = useRef({
        appSession,
        locale,
        token
    });
    useEffect(()=>{
        headersValues.current = {
            appSession,
            locale,
            token
        };
    }, [
        appSession,
        locale,
        token
    ]);
    return headersValues;
};
