import { communitiesAdminCommunitiesSchema } from "../../../../lib";
import { request } from "../../../../request";
export const requestCommunitiesAdminCommunitiesProps = {
    schema: communitiesAdminCommunitiesSchema,
    urlBackendPath: "/me/communities/admin"
};
export const requestCommunitiesAdminCommunities = function() {
    let { ...props } = arguments.length > 0 && arguments[0] !== void 0 ? arguments[0] : {};
    return request({
        ...props,
        ...requestCommunitiesAdminCommunitiesProps
    });
};
