import Cookies from "js-cookie";
import z from "zod";
import { consoleErrorPackagesSdk } from "../../../utils";
export const getCookie = (param)=>{
    let { cookies, key, schema } = param;
    var _cookies_get;
    const cookie = cookies ? (_cookies_get = cookies.get(key)) === null || _cookies_get === void 0 ? void 0 : _cookies_get.value : Cookies.get(key);
    if (!cookie) return null;
    try {
        return (schema !== null && schema !== void 0 ? schema : z.string()).parse(cookie.startsWith("[") || cookie.startsWith("{") ? JSON.parse(cookie) : cookie);
    } catch (error) {
        consoleErrorPackagesSdk({
            error,
            heading: "Could not validate ".concat(key, " cookie")
        });
        return null;
    }
};
