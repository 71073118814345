import { campaignDetailsSchema } from "../../../../lib";
import { request } from "../../../../request";
export const requestCampaignDetailsTrusted = (param)=>{
    let { id } = param;
    return request({
        next: {
            tags: [
                "campaign-details",
                "campaign-details-".concat(id)
            ],
            revalidate: 60 * 60
        },
        requestType: "trusted",
        schema: campaignDetailsSchema,
        urlBackendPath: "/trusted/campaign-details/".concat(id)
    });
};
