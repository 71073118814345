import { radioStationDetailsSchema } from "../../../lib";
import { request } from "../../../request";
export const requestRadioStationTrusted = (param)=>{
    let { id } = param;
    return request({
        next: {
            tags: [
                "radio-station-".concat(id)
            ],
            revalidate: 60 * 60
        },
        requestType: "trusted",
        schema: radioStationDetailsSchema,
        urlBackendPath: "/trusted/radio-station-details/".concat(id)
    });
};
