import { z } from "zod";
export const referenceTypeEnum = z.enum([
    "collection_and_session",
    "daily_quote",
    "deeplink_card",
    "hallow_plus_banner",
    "moodcheck",
    "prayer",
    "trivia",
    "collection",
    "challenge",
    "suggested_routine",
    "prayer_goal",
    "daily",
    "chapter",
    "radio_station",
    "create_group",
    "invite_to_group",
    "alerts",
    "campaign"
]);
