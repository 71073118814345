/* __next_internal_client_entry_do_not_use__ useRequestMutation auto */ import { useMutation } from "@tanstack/react-query";
import { useSession } from "../../session";
import { request } from "../request";
import { useHeadersValues } from "./useHeadersValues";
export const useRequestMutation = (param)=>{
    let { mutationFnRequestProps, ...props } = param;
    const headersValues = useHeadersValues();
    const { refresh } = useSession();
    return useMutation({
        mutationFn: (props)=>request({
                headersValues: headersValues.current,
                method: "POST",
                ...typeof mutationFnRequestProps === "function" ? mutationFnRequestProps(props) : mutationFnRequestProps,
                refresh
            }),
        ...props
    });
};
