import { z } from "zod";
import { challengeSchema, defaultCollectionSchema } from "./collectionSchema";
export const sectionItemCollectionSchema = z.discriminatedUnion("type", [
    defaultCollectionSchema.omit({
        prayers: true
    }),
    challengeSchema.omit({
        prayers: true
    })
]);
export const sectionItemChallengeSchema = challengeSchema.omit({
    prayers: true
});
