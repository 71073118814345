import { bibleTranslationsSchema } from "../../../lib";
import { request } from "../../../request";
export const requestBible = ()=>request({
        next: {
            revalidate: 3600,
            tags: [
                "bible"
            ]
        },
        requestType: "trusted",
        schema: bibleTranslationsSchema,
        urlBackendPath: "/trusted/bible/translations"
    });
