import { useRequestMutation } from "../../../../request";
export const useRequestCampaignMessage = (param)=>{
    let { id, ...props } = param;
    return {
        mutationPost: useRequestMutation({
            ...props,
            mutationFnRequestProps: (data)=>({
                    body: JSON.stringify(data),
                    urlBackendPath: "/campaigns/".concat(id, "/message")
                })
        })
    };
};
