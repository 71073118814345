import { addMonths, differenceInCalendarDays, isFuture, isPast, subDays } from "__barrel_optimize__?names=addMonths,differenceInCalendarDays,isFuture,isPast,subDays!=!date-fns";
export const isMobileSubscription = (subscription)=>isApple(subscription) || isGoogle(subscription) || isAmazon(subscription);
export const isWebSubscription = (subscription)=>{
    return isStripe(subscription) || isPayPal(subscription);
};
export const isFree = (subscription)=>{
    return (subscription === null || subscription === void 0 ? void 0 : subscription.type) === "hallow";
};
export const isLifetime = (subscription)=>{
    return subscription.product === "lifetime_full_subscription";
};
export const isStripe = (subscription)=>{
    return (subscription === null || subscription === void 0 ? void 0 : subscription.type) === "stripe";
};
export const isPayPal = (subscription)=>{
    return (subscription === null || subscription === void 0 ? void 0 : subscription.type) === "paypal";
};
export const isGoogle = (subscription)=>{
    return (subscription === null || subscription === void 0 ? void 0 : subscription.type) === "google";
};
export const isApple = (subscription)=>{
    return (subscription === null || subscription === void 0 ? void 0 : subscription.type) === "apple";
};
export const isAmazon = (subscription)=>{
    return (subscription === null || subscription === void 0 ? void 0 : subscription.type) === "amazon";
};
export const isFamilyPlan = (type, obj)=>{
    if (type === "product") {
        return (obj === null || obj === void 0 ? void 0 : obj.external_id) === "yearly_family_subscription";
    }
    return (obj === null || obj === void 0 ? void 0 : obj.product) === "yearly_family_subscription";
};
export const isFamilyOwner = (subscription)=>{
    return (subscription === null || subscription === void 0 ? void 0 : subscription.is_owner) === true;
};
export const hasFamilyInvite = ()=>{
    return !!window.sessionStorage.getItem("inviteCode");
};
export const isModifiable = (subscription)=>{
    return !isFamilyPlan("subscription", subscription) && !isFree(subscription);
};
export const isCancellable = (subscription)=>{
    return isWebSubscription(subscription) && !isFree(subscription) && isFamilyOwner(subscription) && isRenewing(subscription);
};
export const isRestartable = (subscription)=>{
    return isWebSubscription(subscription) && !isFree(subscription) && isFamilyOwner(subscription) && !isRenewing(subscription) && !!subscription.price_id;
};
export const isCurrentProduct = (subscription, product)=>{
    return product.external_id === subscription.product;
};
export const currentProduct = (subscription, products)=>{
    return products.find((product)=>isCurrentProduct(subscription, product));
};
export const periodToDays = (period, date)=>{
    switch(period.unit){
        case "day":
            return period.number;
        case "week":
            return period.number * 7;
        case "month":
            return date ? differenceInCalendarDays(addMonths(date, period.number), date) : period.number * 30;
        case "year":
            return period.number * 365;
        default:
            return 0;
    }
};
export const isLongerPeriod = (product1, product2)=>{
    if (!product1 || !product2) return false;
    return periodToDays(product1.billing_period) > periodToDays(product2.billing_period);
};
export const hasExpired = (subscription)=>{
    if (!subscription) return false;
    return isPast(new Date(subscription === null || subscription === void 0 ? void 0 : subscription.end_date));
};
export const isInTrial = (subscription)=>{
    return (subscription === null || subscription === void 0 ? void 0 : subscription.is_trial) && (subscription === null || subscription === void 0 ? void 0 : subscription.trial_ends_at) && isFuture(new Date(subscription.trial_ends_at));
};
export const isRenewing = (subscription)=>{
    return subscription === null || subscription === void 0 ? void 0 : subscription.is_renewing;
};
export const inBillingRetry = (subscription)=>{
    return (subscription === null || subscription === void 0 ? void 0 : subscription.billing_issues_at) && isRenewing(subscription);
};
export const hasMultipleRenewing = (subscriptions)=>{
    return (subscriptions === null || subscriptions === void 0 ? void 0 : subscriptions.filter((sub)=>sub.is_renewing).length) > 1;
};
export const currentStatus = (subscription)=>{
    if (!isRenewing(subscription)) return "cancelled";
    if (isInTrial(subscription)) return "trial";
    return "active";
};
export const nextPaymentDate = (subscription)=>{
    const date = subscription === null || subscription === void 0 ? void 0 : subscription.end_date;
    if (!date) return;
    return subDays(new Date(date), 1);
};
export const monthlyPriceOfYearly = (price)=>{
    // Calculate the price over twelve months
    const monthlyPrice = price / 12;
    // If the price is a whole number, round down to closest .99 when
    // available
    const roundedMonthlyPrice = Math.round(monthlyPrice * 100) / 100;
    if (roundedMonthlyPrice % 1 === 0) return Math.max(0, monthlyPrice - 0.01);
    return monthlyPrice;
};
export const isDefaultPlan = (product)=>{
    return product.billing_period.unit === "year" && product.billing_period.number === 1 && !isFamilyPlan("product", product);
};
