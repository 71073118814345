import { z } from "zod";
import { cloudfrontCookieSchema } from "../cloudfrontCookieSchema";
import { bibleLanguageList } from "./bibleLanguageList";
export const bibleTranslationsSchema = z.object({
    translations: z.object({
        id: z.number(),
        name: z.string(),
        description: z.string(),
        url: z.string().url(),
        language: bibleLanguageList,
        available: z.boolean(),
        version: z.string()
    }).array(),
    cookies: cloudfrontCookieSchema.array()
});
