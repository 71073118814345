import { z } from "zod";
import { productSchema } from "./productSchema";
const introOfferSchema = z.object({
    amount: z.number(),
    locale: z.string()
});
const promoCodeOptionSchema = z.object({
    name: z.string(),
    variables: z.any()
});
export const promoCodeSchema = z.object({
    id: z.number(),
    code: z.string(),
    group: z.string(),
    grants: z.array(promoCodeOptionSchema),
    rules: z.array(promoCodeOptionSchema)
});
const cartErrorSchema = z.object({
    status: z.string(),
    error: z.string(),
    code: z.number()
});
export const cartSchema = z.object({
    total: z.number(),
    pretax_total: z.number(),
    subtotal: z.number(),
    currency: z.string(),
    tax: z.number(),
    renewal_total: z.number(),
    renewal_pretax_total: z.number(),
    renewal_tax: z.number(),
    discount: z.number(),
    needs_postal_code: z.boolean(),
    payment_required: z.boolean(),
    trial_end_date: z.string().datetime(),
    plan: z.object({
        price: z.number(),
        name: z.string(),
        trial_days: z.number(),
        product_id: z.string(),
        period: z.string(),
        platforms: z.array(z.string())
    }),
    product: productSchema,
    intro_offer: introOfferSchema.nullable(),
    promo_code: promoCodeSchema.nullable(),
    supported_platforms: z.array(z.string()),
    errors: z.array(cartErrorSchema)
});
