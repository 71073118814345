import { IntlErrorCode } from "next-intl";
import { consoleErrorPackagesi18n } from "./consoleErrorPackagesi18n";
import { deepMergeMessages } from "./deepMergeMessages";
export const basei18n = (param)=>{
    let { defaultMessages, requestConfig, userMessages } = param;
    return {
        getMessageFallback (param) {
            let { namespace, key, error } = param;
            const message = [
                namespace,
                key
            ].filter((part)=>part != null).join(".");
            if (error.code === IntlErrorCode.MISSING_MESSAGE) return "".concat(message, ": message not translated");
            else {
                consoleErrorPackagesi18n({
                    heading: "".concat(message, " (").concat(error.code, ")"),
                    error: error.message
                });
                return "".concat(message, " (").concat(error.code, "): ").concat(error.message);
            }
        },
        messages: deepMergeMessages({
            defaultMessages,
            userMessages
        }),
        onError (error) {
            if (error.code === IntlErrorCode.MISSING_MESSAGE) consoleErrorPackagesi18n({
                heading: error.code,
                error: error.message
            });
            else throw error;
        },
        timeZone: "UTC",
        ...requestConfig
    };
};
