/* __next_internal_client_entry_do_not_use__ useRequestCommunityUser auto */ import { useRequestQuery } from "../../../../request";
import { keyCommunityUser } from "./keyCommunityUser";
import { getRequestCommunityUserProps } from "./requestCommunityUser";
export const useRequestCommunityUser = (param)=>{
    let { id, ...props } = param;
    return {
        query: useRequestQuery({
            ...props,
            queryFnRequestProps: getRequestCommunityUserProps({
                id
            }),
            queryKey: keyCommunityUser({
                id
            })
        })
    };
};
