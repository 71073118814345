/* __next_internal_client_entry_do_not_use__ useRequestMe auto */ import { useLocale } from "@packages/i18n";
import { useQueryClient } from "@tanstack/react-query";
import { z } from "zod";
import { useRequestMutation, useRequestQuery } from "../../../request";
import { keyAvatars } from "../avatars";
import { keyMe } from "./keyMe";
import { requestMeProps } from "./requestMe";
export const useRequestMe = ()=>{
    const locale = useLocale();
    const queryClient = useQueryClient();
    return {
        mutationAvatar: useRequestMutation({
            mutationFnRequestProps: (data)=>({
                    body: JSON.stringify(data),
                    method: "PUT",
                    urlBackendPath: "/me/avatar"
                }),
            onSuccess: ()=>{
                queryClient.invalidateQueries({
                    queryKey: [
                        keyAvatars(),
                        locale
                    ]
                });
                queryClient.invalidateQueries({
                    queryKey: [
                        keyMe(),
                        locale
                    ]
                });
            }
        }),
        mutationDelete: useRequestMutation({
            mutationFnRequestProps: {
                method: "DELETE",
                schema: z.boolean(),
                urlBackendPath: "/me"
            },
            onSuccess: ()=>queryClient.invalidateQueries({
                    queryKey: [
                        keyMe(),
                        locale
                    ]
                })
        }),
        mutationEmail: useRequestMutation({
            mutationFnRequestProps: (data)=>({
                    body: JSON.stringify(data),
                    method: "PUT",
                    urlBackendPath: "/me/email"
                }),
            onSuccess: ()=>queryClient.invalidateQueries({
                    queryKey: [
                        keyMe(),
                        locale
                    ]
                })
        }),
        mutationPassword: useRequestMutation({
            mutationFnRequestProps: (data)=>({
                    body: JSON.stringify(data),
                    method: "PUT",
                    urlBackendPath: "/me/password"
                }),
            onSuccess: ()=>queryClient.invalidateQueries({
                    queryKey: [
                        keyMe(),
                        locale
                    ]
                })
        }),
        mutationPhone: useRequestMutation({
            mutationFnRequestProps: (data)=>({
                    body: JSON.stringify(data),
                    method: "PUT",
                    urlBackendPath: "/me/phone"
                }),
            onSuccess: ()=>queryClient.invalidateQueries({
                    queryKey: [
                        keyMe(),
                        locale
                    ]
                })
        }),
        mutationPut: useRequestMutation({
            mutationFnRequestProps: (data)=>({
                    body: JSON.stringify(data),
                    method: "PUT",
                    urlBackendPath: "/me"
                }),
            onSuccess: ()=>queryClient.invalidateQueries({
                    queryKey: [
                        keyMe(),
                        locale
                    ]
                })
        }),
        mutationResetPassword: useRequestMutation({
            mutationFnRequestProps: (data)=>({
                    body: JSON.stringify(data),
                    requestType: "default",
                    urlBackendPath: "/password/forgot"
                })
        }),
        mutationMergeAccountCheck: useRequestMutation({
            mutationFnRequestProps: (data)=>({
                    body: JSON.stringify(data),
                    urlBackendPath: "/me/account-merge/check"
                })
        }),
        mutationMergeAccountMerge: useRequestMutation({
            mutationFnRequestProps: (data)=>({
                    body: JSON.stringify(data),
                    urlBackendPath: "/me/account-merge/merge"
                })
        }),
        query: useRequestQuery({
            queryFnRequestProps: requestMeProps,
            queryKey: keyMe()
        })
    };
};
