/* __next_internal_client_entry_do_not_use__ useRequestCommunitiesAdminMember auto */ import { useLocale } from "@packages/i18n";
import { useQueryClient } from "@tanstack/react-query";
import { useRequestMutation } from "../../../../../request";
import { keyMe } from "../../../me";
import { keyCommunityMember, keyCommunityMembers } from "../../members";
import { keyCommunitiesAdminCommunities } from "../keyCommunitiesAdminCommunities";
import { keyCommunitiesAdminCommunity } from "../keyCommunitiesAdminCommunity";
export const useRequestCommunitiesAdminMember = (param)=>{
    let { communityId, userId, mutationProps = {} } = param;
    const locale = useLocale();
    const queryClient = useQueryClient();
    return {
        mutationDelete: useRequestMutation({
            ...mutationProps,
            mutationFnRequestProps: ()=>({
                    method: "DELETE",
                    urlBackendPath: "/communities/".concat(communityId, "/members/").concat(userId)
                }),
            onSuccess: (data, variables, context)=>{
                queryClient.invalidateQueries({
                    queryKey: [
                        keyCommunitiesAdminCommunity({
                            id: communityId
                        }),
                        locale
                    ]
                });
                queryClient.invalidateQueries({
                    queryKey: [
                        keyCommunitiesAdminCommunities(),
                        locale
                    ]
                });
                queryClient.invalidateQueries({
                    queryKey: [
                        keyCommunityMembers({
                            id: communityId
                        }),
                        locale
                    ]
                });
                queryClient.invalidateQueries({
                    queryKey: [
                        keyMe(),
                        locale
                    ]
                });
                mutationProps === null || mutationProps === void 0 ? void 0 : mutationProps.onSuccess(data, variables, context);
            }
        }),
        mutationPostAdminGrant: useRequestMutation({
            ...mutationProps,
            mutationFnRequestProps: ()=>({
                    urlBackendPath: "/me/communities/".concat(communityId, "/admin/").concat(userId, "/grant")
                }),
            onSuccess: (data, variables, context)=>{
                queryClient.invalidateQueries({
                    queryKey: [
                        keyCommunityMembers({
                            id: communityId
                        }),
                        locale
                    ]
                });
                queryClient.invalidateQueries({
                    queryKey: [
                        keyCommunityMember({
                            communityId,
                            userId
                        }),
                        locale
                    ]
                });
                mutationProps === null || mutationProps === void 0 ? void 0 : mutationProps.onSuccess(data, variables, context);
            }
        }),
        mutationPostAdminRevoke: useRequestMutation({
            ...mutationProps,
            mutationFnRequestProps: ()=>({
                    urlBackendPath: "/me/communities/".concat(communityId, "/admin/").concat(userId, "/revoke")
                }),
            onSuccess: (data, variables, context)=>{
                queryClient.invalidateQueries({
                    queryKey: [
                        keyCommunityMembers({
                            id: communityId
                        }),
                        locale
                    ]
                });
                queryClient.invalidateQueries({
                    queryKey: [
                        keyCommunityMember({
                            communityId,
                            userId
                        }),
                        locale
                    ]
                });
                queryClient.invalidateQueries({
                    queryKey: [
                        keyMe(),
                        locale
                    ]
                });
                mutationProps === null || mutationProps === void 0 ? void 0 : mutationProps.onSuccess(data, variables, context);
            }
        })
    };
};
