/* __next_internal_client_entry_do_not_use__ useRequestMeRecents auto */ import { sectionSchema } from "../../../../lib";
import { useRequestQuery } from "../../../../request";
import { keyMeRecents } from "./keyMeRecents";
export const useRequestMeRecents = (param)=>{
    let { displayType, ...props } = param;
    return {
        ...props,
        query: useRequestQuery({
            queryFnRequestProps: {
                schema: sectionSchema,
                urlBackendPath: "/me/recents".concat(displayType === "sessions" ? "?format=prayer" : "")
            },
            queryKey: keyMeRecents({
                displayType
            })
        })
    };
};
