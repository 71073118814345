/* __next_internal_client_entry_do_not_use__ useRequestCommunitiesAdminGraph auto */ import { communitiesAdminGraphSchema, dateFormatYYYYMMDD } from "../../../../lib";
import { useRequestQuery } from "../../../../request";
import { keyCommunitiesAdminGraph } from "./keyCommunitiesAdminGraph";
export const useRequestCommunitiesAdminGraph = (param)=>{
    let { endDate, id, startDate, ...props } = param;
    return {
        query: useRequestQuery({
            ...props,
            queryFnRequestProps: {
                schema: communitiesAdminGraphSchema,
                urlBackendPath: "/communities/".concat(id, "/stats/completed-prayers-over-time?").concat(startDate ? "start_date=".concat(dateFormatYYYYMMDD({
                    date: startDate
                })) : "").concat(endDate ? "&end_date=".concat(dateFormatYYYYMMDD({
                    date: endDate
                })) : "")
            },
            queryKey: keyCommunitiesAdminGraph({
                endDate,
                id,
                startDate
            })
        })
    };
};
