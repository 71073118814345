/* __next_internal_client_entry_do_not_use__ useRequest auto */ import { useSession } from "../../session";
import { request } from "../request";
import { useHeadersValues } from "./useHeadersValues";
export const useRequest = (param)=>{
    let { fnRequestProps } = param;
    const headersValues = useHeadersValues();
    const { refresh } = useSession();
    return {
        request: (props)=>request({
                ...props,
                headersValues: headersValues.current,
                refresh,
                ...typeof fnRequestProps === "function" ? fnRequestProps(props) : fnRequestProps
            })
    };
};
