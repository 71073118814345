import { dailyReadingsSchema } from "../../../lib";
import { request } from "../../../request";
export const requestDailyReadings = (param)=>{
    let { date } = param;
    return request({
        requestType: "trusted",
        schema: dailyReadingsSchema,
        urlBackendPath: "/trusted/bible/daily-readings?date=".concat(date, "&language=en")
    });
};
