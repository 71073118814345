export const getURLFromDeeplink = (param)=>{
    let { deeplink, type = "path" } = param;
    if (!deeplink) return null;
    let pieces;
    if (!deeplink.startsWith("hallow")) {
        if (!deeplink.startsWith("http")) return null;
        pieces = deeplink.split("hallow.com/");
        if (pieces.length < 2) return deeplink; // some external URL
    } else {
        pieces = deeplink.split("hallow://");
        if (pieces.length < 2) return null;
    }
    if (type === "path") return "/".concat(pieces[1]);
    const linkPieces = pieces[1].split("/");
    return "/".concat(linkPieces[0], "/").concat(linkPieces[1]);
};
