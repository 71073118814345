import { z } from "zod";
export var FlaggedReason;
(function(FlaggedReason) {
    FlaggedReason["DeceptiveContent"] = "deceptive_content";
    FlaggedReason["HarmfulBehavior"] = "harmful_behavior";
    FlaggedReason["InappropriateContent"] = "inappropriate_content";
    FlaggedReason["MentalHealthAndSafety"] = "mental_health_and_safety";
    FlaggedReason["SomethingElse"] = "something_else";
})(FlaggedReason || (FlaggedReason = {}));
export const flaggedReasonEnum = z.nativeEnum(FlaggedReason);
