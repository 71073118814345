/* __next_internal_client_entry_do_not_use__ useRequestCommunityMembers auto */ import { communityMembersSchema, getRequestNextPageParam } from "../../../../lib";
import { useRequestInfiniteQuery } from "../../../../request";
import { keyCommunityMembers } from "./keyCommunityMembers";
export const useRequestCommunityMembers = (param)=>{
    let { id, ...props } = param;
    return {
        infiniteQuery: useRequestInfiniteQuery({
            getNextPageParam: (lastPage, _allPages, lastPageParam)=>getRequestNextPageParam({
                    total: lastPage.total,
                    lastPageParam,
                    pageSize: 50
                }),
            initialPageParam: 0,
            ...props,
            queryFnRequestProps: (param)=>{
                let { pageParam } = param;
                return {
                    schema: communityMembersSchema,
                    urlBackendPath: "/communities/".concat(id, "/members?page=").concat(pageParam)
                };
            },
            queryKey: keyCommunityMembers({
                id
            })
        })
    };
};
