import { z } from "zod";
import { imagesSchema } from "../imagesSchema";
const categorySchema = z.object({
    slug: z.string(),
    name: z.string()
});
export const articleSchema = z.object({
    id: z.number(),
    title: z.string(),
    link: z.string(),
    full_title: z.string(),
    categories: z.array(categorySchema),
    images: imagesSchema
});
