/* __next_internal_client_entry_do_not_use__ useRequestFavorites auto */ import { sectionSchema } from "../../../lib";
import { useRequestQuery } from "../../../request";
import { keyFavorites } from "./keyFavorites";
export const useRequestFavorites = (param)=>{
    let { displayType, ...props } = param;
    return {
        ...props,
        query: useRequestQuery({
            queryFnRequestProps: {
                schema: sectionSchema,
                urlBackendPath: "/me/favorites".concat(displayType === "sessions" ? "?format=prayer" : "")
            },
            queryKey: keyFavorites({
                displayType
            })
        })
    };
};
