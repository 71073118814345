export const keyMeRecents = (param)=>{
    let { displayType } = param;
    return [
        "me",
        "recents",
        {
            displayType
        }
    ];
};
