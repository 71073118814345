/* __next_internal_client_entry_do_not_use__ useRequestSession auto */ import { useRequest } from "../../../request";
export const useRequestSession = function() {
    let { ...props } = arguments.length > 0 && arguments[0] !== void 0 ? arguments[0] : {};
    return {
        requestPut: useRequest({
            ...props,
            fnRequestProps: (data)=>{
                const { duration, id, ...body } = data;
                return {
                    body: JSON.stringify({
                        ...body,
                        duration: duration > 0 ? duration : undefined
                    }),
                    method: "PUT",
                    urlBackendPath: "/sessions/".concat(id)
                };
            }
        })
    };
};
