/* __next_internal_client_entry_do_not_use__ useRequestCollectionNextUp auto */ import { basePrayerSchema } from "../../../../lib";
import { useRequest } from "../../../../request";
export const useRequestCollectionNextUp = function() {
    let { ...props } = arguments.length > 0 && arguments[0] !== void 0 ? arguments[0] : {};
    return {
        requestGet: useRequest({
            ...props,
            fnRequestProps: (param)=>{
                let { id } = param;
                return {
                    schema: basePrayerSchema,
                    urlBackendPath: "/collections/".concat(id, "/nextup")
                };
            }
        })
    };
};
