/* __next_internal_client_entry_do_not_use__ useSession auto */ import { useContext, useEffect } from "react";
import { redirectToAuth } from "../../lib";
import { sessionContext } from "../sessionContext";
export const useSession = function() {
    let { onUnauthenticated, required = false } = arguments.length > 0 && arguments[0] !== void 0 ? arguments[0] : {};
    const { status, ...rest } = useContext(sessionContext);
    useEffect(()=>{
        if (status === "unauthenticated" && required) {
            if (onUnauthenticated) onUnauthenticated();
            else redirectToAuth();
        }
    }, [
        required,
        status
    ]);
    return {
        status,
        ...rest
    };
};
