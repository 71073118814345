/* __next_internal_client_entry_do_not_use__ sessionContext auto */ import { createContext } from "react";
export const sessionContext = createContext({
    hasRefreshToken: false,
    logout: ()=>{},
    refresh: ()=>Promise.resolve({}),
    setStatus: ()=>{},
    setToken: ()=>{},
    status: "loading",
    token: null
});
