/* __next_internal_client_entry_do_not_use__ useRequestQueueNextItems auto */ import { queueSchema } from "../../../../lib";
import { useRequestMutation } from "../../../../request";
export const useRequestQueueNextItems = function() {
    let { ...props } = arguments.length > 0 && arguments[0] !== void 0 ? arguments[0] : {};
    return {
        mutatePost: useRequestMutation({
            ...props,
            mutationFnRequestProps: ()=>({
                    method: "POST",
                    schema: queueSchema,
                    urlBackendPath: "/queue/next-items"
                })
        })
    };
};
