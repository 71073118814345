import { useLocale } from "@packages/i18n";
import { useQueryClient } from "@tanstack/react-query";
import { displayTypeEnum } from "../../../lib";
import { useRequestMutation } from "../../../request";
import { keyFavorites } from "../favorites";
import { keyHome } from "../home";
import { keyPrayer } from "../prayer";
export const useRequestFavorite = (param)=>{
    let { id, ...props } = param;
    const locale = useLocale();
    const queryClient = useQueryClient();
    return {
        mutationPost: useRequestMutation({
            ...props,
            mutationFnRequestProps: {
                urlBackendPath: "/prayers/".concat(id, "/favorite")
            },
            onSuccess: ()=>{
                queryClient.invalidateQueries({
                    queryKey: [
                        keyHome(),
                        locale
                    ]
                });
                queryClient.invalidateQueries({
                    queryKey: [
                        keyPrayer({
                            id
                        }),
                        locale
                    ]
                });
                displayTypeEnum.options.forEach((displayTypeEnumOption)=>queryClient.invalidateQueries({
                        queryKey: [
                            keyFavorites({
                                displayType: displayTypeEnumOption
                            }),
                            locale
                        ]
                    }));
            }
        })
    };
};
