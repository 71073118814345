/* __next_internal_client_entry_do_not_use__ useRequestMePayments auto */ import { paymentsSchema } from "../../../../lib";
import { useRequestQuery } from "../../../../request";
import { keyMePayments } from "./keyMePayments";
export const useRequestMePayments = function() {
    let { ...props } = arguments.length > 0 && arguments[0] !== void 0 ? arguments[0] : {};
    return {
        query: useRequestQuery({
            ...props,
            queryFnRequestProps: {
                schema: paymentsSchema,
                urlBackendPath: "/me/payments"
            },
            queryKey: keyMePayments()
        })
    };
};
