/* __next_internal_client_entry_do_not_use__ useRequestTranscript auto */ import { transcriptSchema } from "../../../lib";
import { useRequestQuery } from "../../../request";
import { keyTranscript } from "./keyTranscript";
export const useRequestTranscript = (param)=>{
    let { id, ...props } = param;
    return {
        query: useRequestQuery({
            enabled: typeof id === "number",
            ...props,
            queryFnRequestProps: {
                schema: transcriptSchema,
                urlBackendPath: "/audio/".concat(id, "/transcript"),
                next: {
                    revalidate: 3600
                }
            },
            queryKey: keyTranscript({
                id
            })
        })
    };
};
