import { z } from "zod";
import { campaignMessageTypeEnum } from "../../enums";
const campaignMessageSchema = z.object({
    id: z.number(),
    created_at: z.string(),
    text: z.string(),
    // TODO: ask backend, are all of these properties on meSchema?
    user: z.object({
        bio: z.string().nullable(),
        id: z.number(),
        image_url: z.string().nullable().optional(),
        is_friend: z.boolean(),
        is_self: z.boolean(),
        last_name: z.string().nullable(),
        name: z.string(),
        relation_description: z.string().nullable(),
        username: z.string().nullable()
    }),
    message_type: campaignMessageTypeEnum.optional()
});
export default campaignMessageSchema;
