import { meSchema } from "../../../lib";
import { request } from "../../../request";
export const requestMeProps = {
    schema: meSchema,
    urlBackendPath: "/me"
};
export const requestMe = function() {
    let { ...props } = arguments.length > 0 && arguments[0] !== void 0 ? arguments[0] : {};
    return request({
        ...props,
        ...requestMeProps
    });
};
