/* __next_internal_client_entry_do_not_use__ useRequestCommunitiesStatsTotalsOverTimeProps auto */ import { communitiesAdminStatsSchema, dateFormatYYYYMMDD } from "../../../../../../lib";
import { useRequestQuery } from "../../../../../../request";
import { keyCommunitiesStatsTotalsOverTime } from "./keyCommunitiesStatsTotalsOverTime";
export const useRequestCommunitiesStatsTotalsOverTimeProps = (param)=>{
    let { endDate, id, startDate, ...props } = param;
    return {
        query: useRequestQuery({
            ...props,
            queryFnRequestProps: {
                schema: communitiesAdminStatsSchema,
                urlBackendPath: "/communities/".concat(id, "/stats/totals-over-time?").concat(startDate ? "start_date=".concat(dateFormatYYYYMMDD({
                    date: startDate
                })) : "").concat(endDate ? "&end_date=".concat(dateFormatYYYYMMDD({
                    date: endDate
                })) : "")
            },
            queryKey: keyCommunitiesStatsTotalsOverTime({
                endDate,
                id,
                startDate
            })
        })
    };
};
