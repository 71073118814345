import { determineClientOrServer } from "../../utils";
import { getSessionRefreshToken } from "./getSessionRefreshToken";
import { requestSessionRefresh } from "./requests";
export const getSessionRequestHeaders = async (param)=>{
    let { valueToken } = param;
    if (valueToken) return {
        Authorization: "Bearer ".concat(valueToken)
    };
    let cookies;
    if (determineClientOrServer() === "server") {
        const { cookies: nextHeadersCookies } = await import("next/headers");
        cookies = nextHeadersCookies();
    }
    const { refreshToken } = getSessionRefreshToken({
        cookies
    });
    const { access_token: token } = await requestSessionRefresh({
        refreshToken
    });
    return {
        Authorization: "Bearer ".concat(token)
    };
};
