/* __next_internal_client_entry_do_not_use__ useRequestQueueCurrentItemIndex auto */ import { useRequestMutation } from "../../../../request";
export const useRequestQueueCurrentItemIndex = function() {
    let { ...props } = arguments.length > 0 && arguments[0] !== void 0 ? arguments[0] : {};
    return {
        mutatePut: useRequestMutation({
            mutationFnRequestProps: (data)=>({
                    body: JSON.stringify(data),
                    method: "PUT",
                    urlBackendPath: "/queue/current-item-index"
                })
        })
    };
};
