/* __next_internal_client_entry_do_not_use__ useRequestCommunitiesAdminCommunities auto */ import { useRequestQuery } from "../../../../request";
import { keyCommunitiesAdminCommunities } from "./keyCommunitiesAdminCommunities";
import { requestCommunitiesAdminCommunitiesProps } from "./requestCommunitiesAdminCommunities";
export const useRequestCommunitiesAdminCommunities = function() {
    let { ...props } = arguments.length > 0 && arguments[0] !== void 0 ? arguments[0] : {};
    return {
        query: useRequestQuery({
            ...props,
            queryFnRequestProps: requestCommunitiesAdminCommunitiesProps,
            queryKey: keyCommunitiesAdminCommunities()
        })
    };
};
