/* __next_internal_client_entry_do_not_use__ useRequestCommunitiesAdminCommunity auto */ import { useLocale } from "@packages/i18n";
import { useQueryClient } from "@tanstack/react-query";
import { communitySchema } from "../../../../lib/types/schema/community/communitySchema";
import { useRequestMutation, useRequestQuery } from "../../../../request";
import { keyCommunitiesChildren } from "../children";
import { keyCommunitiesAdminCommunities } from "./keyCommunitiesAdminCommunities";
import { keyCommunitiesAdminCommunity } from "./keyCommunitiesAdminCommunity";
import { requestCommunitiesAdminCommunityProps } from "./requestCommunitiesAdminCommunity";
export const useRequestCommunitiesAdminCommunity = (param)=>{
    let { id } = param;
    const locale = useLocale();
    const queryClient = useQueryClient();
    return {
        mutationPut: useRequestMutation({
            mutationFnRequestProps: (data)=>({
                    body: JSON.stringify(data),
                    method: "PUT",
                    schema: communitySchema,
                    urlBackendPath: "/me/communities/".concat(id)
                }),
            onSuccess: ()=>{
                queryClient.invalidateQueries({
                    queryKey: [
                        keyCommunitiesAdminCommunity({
                            id
                        }),
                        locale
                    ]
                });
                queryClient.invalidateQueries({
                    queryKey: [
                        keyCommunitiesAdminCommunities(),
                        locale
                    ]
                });
            }
        }),
        mutationUnlink: useRequestMutation({
            mutationFnRequestProps: (data)=>({
                    body: JSON.stringify(data),
                    method: "PUT",
                    urlBackendPath: "/communities/".concat(data.id, "/unlink")
                }),
            onSuccess: ()=>{
                queryClient.invalidateQueries({
                    queryKey: [
                        keyCommunitiesChildren({
                            id
                        }),
                        locale
                    ]
                });
            }
        }),
        query: useRequestQuery({
            queryFnRequestProps: {
                urlBackendPath: "/communities/".concat(id),
                ...requestCommunitiesAdminCommunityProps
            },
            queryKey: keyCommunitiesAdminCommunity({
                id
            })
        })
    };
};
