import { communitySchema } from "../../../../lib/types/schema/community/communitySchema";
import { request } from "../../../../request";
export const requestCommunitiesMembers = (param)=>{
    let { id, ...props } = param;
    return request({
        ...props,
        schema: communitySchema,
        urlBackendPath: "/me/communities/".concat(id, "/members")
    });
};
