/* __next_internal_client_entry_do_not_use__ useRequestBibleLinkedContent auto */ import { bibleDeeplinkSchema } from "../../../lib";
import { useRequestQuery } from "../../../request";
import { keyBibleLinkedContent } from "./keyBibleLinkedContent";
export const useRequestBibleLinkedContent = (param)=>{
    let { chapterId, translationId, ...props } = param;
    return {
        query: useRequestQuery({
            ...props,
            queryFnRequestProps: {
                schema: bibleDeeplinkSchema,
                urlBackendPath: "/bible/".concat(translationId, "/content/").concat(chapterId)
            },
            queryKey: keyBibleLinkedContent({
                translationId,
                chapterId
            })
        })
    };
};
