/* __next_internal_client_entry_do_not_use__ usePathMatch auto */ import { useLocale } from "@packages/i18n";
import { usePathname } from "next/navigation";
import { pathMatchFn } from "./pathMatchFn";
export const usePathMatch = ()=>{
    const pathname = usePathname();
    const locale = useLocale();
    return function(href) {
        let exact = arguments.length > 1 && arguments[1] !== void 0 ? arguments[1] : false;
        return pathMatchFn(href, exact, pathname, locale);
    };
};
