/* __next_internal_client_entry_do_not_use__ useRequestRegister auto */ import { setCookieHasRefreshToken } from "../../../lib";
import { useRequestMutation } from "../../../request";
import { useSession } from "../../../session";
import { registerSchema } from "./types";
export const useRequestRegister = ()=>{
    const { setStatus } = useSession();
    return {
        mutationPostEmailPassword: useRequestMutation({
            mutationFnRequestProps: (data)=>({
                    body: JSON.stringify(data),
                    requestType: "default",
                    schema: registerSchema,
                    urlBackendPath: "/register"
                }),
            onSuccess: ()=>{
                setCookieHasRefreshToken({
                    value: true
                });
                setStatus("authenticated");
            }
        })
    };
};
