/* __next_internal_client_entry_do_not_use__ useRequestVerify auto */ import { useRequestMutation } from "../../../request";
export const useRequestVerify = ()=>({
        verifyEmail: useRequestMutation({
            mutationFnRequestProps: (data)=>({
                    body: JSON.stringify(data),
                    method: "POST",
                    urlBackendPath: "/verify/email",
                    requestType: "default"
                })
        })
    });
