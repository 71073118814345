import { deleteCookie, getCookie, setCookie } from "./utils";
export const COOKIE_KEY_HAS_REFRESH_TOKEN = "hashlwrfh";
export const deleteCookieHasRefreshToken = function() {
    let { ...props } = arguments.length > 0 && arguments[0] !== void 0 ? arguments[0] : {};
    return deleteCookie({
        key: COOKIE_KEY_HAS_REFRESH_TOKEN,
        ...props
    });
};
export const getCookieHasRefreshToken = function() {
    let { ...props } = arguments.length > 0 && arguments[0] !== void 0 ? arguments[0] : {};
    return getCookie({
        key: COOKIE_KEY_HAS_REFRESH_TOKEN,
        ...props
    });
};
export const setCookieHasRefreshToken = (param)=>{
    let { ...props } = param;
    return setCookie({
        key: COOKIE_KEY_HAS_REFRESH_TOKEN,
        ...props
    });
};
