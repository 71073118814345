import { communityChallengeDetailsSchema } from "../../../../lib/types/schema/community/challenge/communityChallengeDetailsSchema";
import { request } from "../../../../request";
export const requestCommunityChallengeUsers = (param)=>{
    let { id, ...props } = param;
    return request({
        ...props,
        requestType: "authenticated",
        schema: communityChallengeDetailsSchema,
        urlBackendPath: "/community-challenges/".concat(id, "/users")
    });
};
