import { z } from "zod";
import { playlistContentSchema } from "../playlist/playlistContentResponseSchema";
import campaignMessageSchema from "./campaignMessageSchema";
import { campaignSchema } from "./campaignSchema";
export const campaignDetailsSchema = z.object({
    campaign: campaignSchema,
    playlist_content: z.array(playlistContentSchema),
    latest_posts: z.array(campaignMessageSchema),
    updates: z.array(campaignMessageSchema)
});
