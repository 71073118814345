import { z } from "zod";
import { collectionSchema } from "../collection";
import { guideSchema } from "../guideSchema";
import { playableAudio } from "../playableAudioSchema";
import { basePrayerSchema } from "../prayer";
import { radioSongSchema, radioStationSchema } from "../radio-station";
import { trackSchema } from "../trackSchema";
export const prayerQueueItemSchema = z.object({
    collection: collectionSchema.omit({
        prayers: true
    }),
    prayer: basePrayerSchema.extend({
        tracks: z.array(trackSchema),
        guides: z.array(guideSchema)
    }),
    selected_audio: playableAudio,
    type: z.literal("prayer")
});
export const radioQueueItemSchema = z.object({
    radio_song: radioSongSchema.optional(),
    radio_station: radioStationSchema.optional(),
    selected_audio: playableAudio,
    type: z.literal("radio_song")
});
export const queueItemSchema = prayerQueueItemSchema.or(radioQueueItemSchema);
