import Cookies from "js-cookie";
import { z } from "zod";
import { HallowCookies } from "../constants";
export const getValidatedCookie = function(key, schema) {
    let isJson = arguments.length > 2 && arguments[2] !== void 0 ? arguments[2] : true;
    const rawCookie = Cookies.get(key);
    if (!rawCookie) return null;
    try {
        let parsedCookie = rawCookie;
        if (isJson) parsedCookie = JSON.parse(rawCookie);
        const result = schema.parse(parsedCookie);
        return result;
    } catch (error) {
        console.error("Failed to validate ".concat(key, " cookie:"), error);
        return null;
    }
};
const ConsentSchema = z.array(z.number());
export const getValidatedCookiesHlwConsent = ()=>{
    return getValidatedCookie(HallowCookies.CONSENT, ConsentSchema);
};
export const getValidatedCookiesHighPrivacyZone = ()=>{
    return getValidatedCookie("hlwhighprivacy", z.boolean());
};
export const setCookiesHighPrivacyZone = (value)=>{
    Cookies.withConverter({
        write: function(value) {
            return value;
        }
    }).set("hlwhighprivacy", value);
};
