/* __next_internal_client_entry_do_not_use__ useRequestQueueItems auto */ import { useRequestMutation } from "../../../../request";
export const useRequestQueueItems = function() {
    let { ...props } = arguments.length > 0 && arguments[0] !== void 0 ? arguments[0] : {};
    return {
        mutatePost: useRequestMutation({
            ...props,
            mutationFnRequestProps: (data)=>({
                    body: JSON.stringify(data),
                    method: "POST",
                    urlBackendPath: "/queue/items"
                })
        })
    };
};
