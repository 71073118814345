/* __next_internal_client_entry_do_not_use__ useRequestCommunitiesAdminFlaggable auto */ import { communitiesAdminFlaggableSchema } from "../../../../../lib";
import { useRequestQuery } from "../../../../../request";
import { keyCommunitiesAdminFlaggable } from "./keyCommunitiesAdminFlaggable";
export const useRequestCommunitiesAdminFlaggable = (param)=>{
    let { id, staleTime, ...props } = param;
    return {
        query: useRequestQuery({
            enabledWithoutSessionToken: false,
            staleTime,
            ...props,
            queryFnRequestProps: {
                schema: communitiesAdminFlaggableSchema,
                urlBackendPath: "/moderation/communities/".concat(id)
            },
            queryKey: keyCommunitiesAdminFlaggable({
                hasStaleTime: staleTime ? true : false,
                id
            })
        })
    };
};
