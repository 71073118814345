import { z } from "zod";
export const sectionItemTypeEnum = z.enum([
    "small",
    "medium",
    "large",
    "moodcheck",
    "daily_quote",
    "trivia",
    "hallow_plus_banner",
    "create_group",
    "invite_to_group",
    "alerts",
    "prayer_goal_row",
    "prayer_goal",
    "suggested_routine",
    "deeplink_card",
    "deeplink_card_medium",
    "deeplink_card_small",
    "deeplink_card_playable",
    "player_card",
    "campaign"
]);
