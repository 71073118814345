import { bibleContentSchema, signCFUrl, URL_BIBLE } from "../../../lib";
import { validatePromiseSchema } from "../../../utils";
export const requestBibleHTML = async (param)=>{
    let { translationId, bookId, chapter, cookies } = param;
    const htmlURL = signCFUrl("".concat(URL_BIBLE, "/translations/").concat(translationId, "/").concat(bookId, "/").concat(chapter, ".html"), cookies);
    const cssURL = signCFUrl("".concat(URL_BIBLE, "/translations/").concat(translationId, "/").concat(translationId, ".css"), cookies);
    const htmlPromise = fetch(htmlURL, {
        method: "GET",
        next: {
            tags: [
                "bible"
            ]
        }
    }).then((htmlResponse)=>{
        if (htmlResponse.ok) return htmlResponse.text();
        console.error("failed to fetch", htmlURL);
        throw htmlResponse;
    });
    const cssPromise = fetch(cssURL, {
        method: "GET",
        next: {
            tags: [
                "bible"
            ]
        }
    }).then((cssResponse)=>{
        if (cssResponse.ok) return cssResponse.text();
        console.error("failed to fetch", cssURL);
        throw cssResponse;
    });
    return validatePromiseSchema({
        promise: Promise.all([
            htmlPromise,
            cssPromise
        ]).then((param)=>{
            let [html, css] = param;
            return {
                html,
                css
            };
        }),
        schema: bibleContentSchema
    });
};
