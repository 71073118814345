import { z } from "zod";
import { basePrayerInCollectionSchema } from "../prayer";
import { baseCollectionSchema } from "./baseCollectionSchema";
export const trustedCollectionSchema = baseCollectionSchema.omit({
    has_high_res_illo: true
}).extend({
    prayers: z.array(basePrayerInCollectionSchema.omit({
        selected_track_id: true,
        is_redeemed: true,
        is_completed: true,
        is_favorite: true,
        has_access: true
    }))
});
