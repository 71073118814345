/* __next_internal_client_entry_do_not_use__ useRequestCommunitiesMostPopular auto */ import { communitiesAdminMostPopularSchema } from "../../../../../../lib";
import { useRequestQuery } from "../../../../../../request";
import { keyCommunitiesMostPopular } from "./keyCommunitiesMostPopular";
export const useRequestCommunitiesMostPopular = (param)=>{
    let { id, ...props } = param;
    return {
        ...props,
        query: useRequestQuery({
            queryFnRequestProps: {
                schema: communitiesAdminMostPopularSchema,
                urlBackendPath: "/communities/".concat(id, "/stats/most-popular")
            },
            queryKey: keyCommunitiesMostPopular({
                id
            })
        })
    };
};
