import { request } from "../../../request";
import { sessionRefreshSchema } from "../../types";
export const requestSessionRefresh = function() {
    let { refreshToken } = arguments.length > 0 && arguments[0] !== void 0 ? arguments[0] : {
        refreshToken: null
    };
    return request({
        body: refreshToken ? JSON.stringify({
            refresh_token: refreshToken
        }) : null,
        method: "POST",
        requestType: "default",
        schema: sessionRefreshSchema,
        urlBackendPath: "/oauth/refresh"
    });
};
