import { deleteCookie, getCookie, setCookie } from "./utils";
export const COOKIE_KEY_AJS_ANONYMOUS_ID = "ajs_anonymous_id";
export const deleteCookieAjsAnonymousId = function() {
    let { ...props } = arguments.length > 0 && arguments[0] !== void 0 ? arguments[0] : {};
    return deleteCookie({
        key: COOKIE_KEY_AJS_ANONYMOUS_ID,
        ...props
    });
};
export const getCookieAjsAnonymousId = function() {
    let { ...props } = arguments.length > 0 && arguments[0] !== void 0 ? arguments[0] : {};
    return getCookie({
        key: COOKIE_KEY_AJS_ANONYMOUS_ID,
        ...props
    });
};
export const setCookieAjsAnonymousId = (param)=>{
    let { ...props } = param;
    return setCookie({
        key: COOKIE_KEY_AJS_ANONYMOUS_ID,
        ...props
    });
};
