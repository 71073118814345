/* __next_internal_client_entry_do_not_use__ useRequestGift auto */ import { productsSchema } from "../../../lib";
import { useRequestMutation, useRequestQuery } from "../../../request";
import { keyGiftCards } from "./keyProducts";
export const useRequestGift = ()=>{
    return {
        query: useRequestQuery({
            queryFnRequestProps: {
                schema: productsSchema,
                urlBackendPath: "/products/gift-cards",
                requestType: "default"
            },
            enabledWithoutSessionToken: true,
            queryKey: keyGiftCards()
        }),
        mutationPurchase: useRequestMutation({
            mutationFnRequestProps: (data)=>({
                    body: JSON.stringify({
                        count: data.count,
                        payment_method: data.paymentMethod,
                        payment_platform: data.paymentPlatform,
                        price_id: data.priceId,
                        email: data.email
                    }),
                    method: "POST",
                    urlBackendPath: "/gift",
                    requestType: "default"
                })
        })
    };
};
