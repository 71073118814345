const escapeRegExp = (str)=>{
    return str.replace(/[.*+?^${}()|[\]\\]/g, "\\$&"); // $& means the whole matched string
};
export const trim = function(str) {
    let trim = arguments.length > 1 && arguments[1] !== void 0 ? arguments[1] : " ", start = arguments.length > 2 && arguments[2] !== void 0 ? arguments[2] : true, end = arguments.length > 3 && arguments[3] !== void 0 ? arguments[3] : true;
    if (trim === " ") {
        if (end && start) return str.trim();
        if (start) return str.trimStart();
        return str.trimEnd();
    }
    trim = escapeRegExp(trim);
    const startRe = new RegExp("^(?:".concat(trim, ")+"), "g");
    const endRe = new RegExp("(?:".concat(trim, ")+$"), "g");
    if (start) str = str.replaceAll(startRe, "");
    if (end) str = str.replaceAll(endRe, "");
    return str;
};
