import { pagesSchema } from "../../../lib";
import { request } from "../../../request";
export const requestPagesTrusted = (param)=>{
    let { page } = param;
    return request({
        next: {
            tags: [
                "pages",
                "pages-".concat(page)
            ],
            revalidate: 60 * 60
        },
        requestType: "trusted",
        schema: pagesSchema,
        urlBackendPath: "/trusted/pages/".concat(page)
    });
};
