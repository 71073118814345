/* __next_internal_client_entry_do_not_use__ useRequestCommunityActivityFeed auto */ import { communityActivityFeedSchema } from "../../../../lib/types/schema/community/activityFeed";
import { useRequestInfiniteQuery } from "../../../../request";
import { keyCommunityActivityFeed } from "./keyCommunityActivityFeed";
export const useRequestCommunityActivityFeed = (param)=>{
    let { id, type, ...props } = param;
    return {
        infiniteQuery: useRequestInfiniteQuery({
            getNextPageParam: (lastPage)=>{
                var _lastPage_results_, _lastPage_results;
                return (lastPage === null || lastPage === void 0 ? void 0 : (_lastPage_results_ = lastPage.results[(lastPage === null || lastPage === void 0 ? void 0 : (_lastPage_results = lastPage.results) === null || _lastPage_results === void 0 ? void 0 : _lastPage_results.length) - 1]) === null || _lastPage_results_ === void 0 ? void 0 : _lastPage_results_.id) || null;
            },
            initialPageParam: -1,
            ...props,
            queryFnRequestProps: (param)=>{
                let { pageParam } = param;
                const searchParams = new URLSearchParams("?per_page=10");
                if (pageParam && pageParam !== -1) searchParams.set("last_id", pageParam);
                if (type) searchParams.set("post_types[]", type);
                return {
                    schema: communityActivityFeedSchema,
                    urlBackendPath: "/me/communities/".concat(id, "/activity-feed").concat(searchParams.toString().length > 0 ? "?".concat(searchParams.toString()) : "")
                };
            },
            queryKey: keyCommunityActivityFeed({
                id,
                type
            })
        })
    };
};
