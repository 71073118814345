import { z } from "zod";
import { USERNAME_MATCHER } from "../../../utils";
import { subscriptionSchema } from "../subscription/subscriptionSchema";
const goalSchema = z.object({
    id: z.number(),
    sync_uuid: z.string(),
    type: z.string(),
    goal: z.number(),
    completed: z.number(),
    created_at: z.string().datetime()
});
export const meSchema = z.object({
    id: z.number(),
    image_url: z.string(),
    name: z.string().max(255).trim(),
    last_name: z.string().max(255).trim().nullable(),
    email: z.string().email().trim(),
    phone: z.string().trim().nullable(),
    guide: z.string().nullable(),
    goal: goalSchema.optional().nullable(),
    is_herald: z.boolean().optional().nullable(),
    streak: z.number(),
    longest_streak: z.number().nullable(),
    time_in_prayer: z.number(),
    sync_health: z.boolean(),
    sync_journals: z.boolean(),
    has_seen_plan_message: z.boolean().optional(),
    has_password: z.boolean().optional(),
    notifications_enabled: z.boolean(),
    timezone: z.string().nullable(),
    language: z.string().max(10).transform((str)=>str.replace(/es-419/, "es")).nullable().optional(),
    background_track_id: z.number().min(0).nullable(),
    adjust_idfa: z.string().optional(),
    appsflyer_id: z.string().nullable().optional(),
    prayer_goals: z.array(z.number()).nullable(),
    motives: z.array(z.number()).nullable().optional(),
    sharing_to: z.array(z.number()).nullable(),
    consent: z.array(z.number()).nullable(),
    is_student: z.boolean().optional().nullable(),
    favorites: z.array(z.object({
        id: z.number(),
        prayer_id: z.number()
    })).optional(),
    sessions: z.array(z.object({
        id: z.number(),
        sync_uuid: z.string(),
        prayer_id: z.number(),
        audio_id: z.number(),
        created_at: z.string().datetime()
    })).optional(),
    subscription: subscriptionSchema.nullable(),
    devices: z.array(z.object({
        type: z.string().nullable(),
        token: z.string(),
        appsflyer_id: z.string().nullable().optional()
    })).optional(),
    goals: z.array(goalSchema).optional(),
    daily_quote_notifications: z.boolean().optional(),
    is_discoverable_by_community: z.boolean().optional(),
    community_notifications_enabled: z.boolean().optional(),
    username: z.string().regex(new RegExp(USERNAME_MATCHER, "i")).min(3).max(15).nullable().optional(),
    has_completed_community_onboarding: z.boolean().optional(),
    has_access_to_parish_admin_dashboard: z.boolean().optional(),
    country: z.string().nullable()
});
