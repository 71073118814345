export const getArtworkMetadata = (images)=>{
    const arr = [];
    if (images.small) {
        arr.push({
            src: images.small,
            sizes: "314x220",
            type: images.small.endsWith("png") ? "image/png" : "image/webp"
        });
    }
    if (images.medium) {
        arr.push({
            src: images.medium,
            sizes: "628x440",
            type: images.medium.endsWith("png") ? "image/png" : "image/webp"
        });
    }
    if (images.large) {
        arr.push({
            src: images.large,
            sizes: "1256x880",
            type: images.large.endsWith("png") ? "image/png" : "image/webp"
        });
    }
    if (images.fallback) {
        arr.push({
            src: images.fallback,
            sizes: "1256x880",
            type: "image/png"
        });
    }
    return arr;
};
