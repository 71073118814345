import { bibleManifestSchema, signCFUrl, URL_BIBLE } from "../../../lib";
import { request } from "../../../request";
export const requestBibleManifest = (param)=>{
    let { cloudfrontCookies, translationId } = param;
    return request({
        next: {
            tags: [
                "bible"
            ]
        },
        requestType: "trusted",
        schema: bibleManifestSchema,
        url: signCFUrl("".concat(URL_BIBLE, "/translations/").concat(translationId, "/manifest.json"), cloudfrontCookies)
    });
};
