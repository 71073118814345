/* __next_internal_client_entry_do_not_use__ useRequestCommunitiesAdminPost auto */ import { useLocale } from "@packages/i18n";
import { useQueryClient } from "@tanstack/react-query";
import { useRequestMutation } from "../../../../request";
import { keyCommunityActivityFeed } from "../activityFeed";
import { keyCommunitiesAdminFlaggable } from "./flaggable";
export const useRequestCommunitiesAdminPost = (param)=>{
    let { id, mutationDeleteProps, mutationResolveProps } = param;
    const locale = useLocale();
    const queryClient = useQueryClient();
    return {
        mutationDelete: useRequestMutation({
            ...mutationDeleteProps,
            mutationFnRequestProps: (param)=>{
                let { communityPostId } = param;
                return {
                    method: "DELETE",
                    urlBackendPath: "/moderation/communities/".concat(id, "/posts/").concat(communityPostId)
                };
            },
            onSuccess: (data, variables, context)=>{
                const { type } = variables;
                queryClient.invalidateQueries({
                    queryKey: [
                        keyCommunitiesAdminFlaggable({
                            id
                        }),
                        locale
                    ]
                });
                queryClient.invalidateQueries({
                    queryKey: [
                        keyCommunityActivityFeed({
                            id
                        }),
                        locale
                    ]
                });
                if (type) {
                    queryClient.invalidateQueries({
                        queryKey: [
                            keyCommunityActivityFeed({
                                id,
                                type
                            }),
                            locale
                        ]
                    });
                }
                if (mutationDeleteProps === null || mutationDeleteProps === void 0 ? void 0 : mutationDeleteProps.onSuccess) mutationDeleteProps.onSuccess(data, variables, context);
            }
        }),
        mutationResolve: useRequestMutation({
            ...mutationResolveProps,
            mutationFnRequestProps: (param)=>{
                let { communityPostId } = param;
                return {
                    urlBackendPath: "/moderation/communities/".concat(id, "/posts/").concat(communityPostId, "/mark-as-reviewed")
                };
            },
            onSuccess: (data, variables, context)=>{
                queryClient.invalidateQueries({
                    queryKey: [
                        keyCommunitiesAdminFlaggable({
                            id
                        }),
                        locale
                    ]
                });
                if (mutationResolveProps === null || mutationResolveProps === void 0 ? void 0 : mutationResolveProps.onSuccess) mutationResolveProps.onSuccess(data, variables, context);
            }
        })
    };
};
