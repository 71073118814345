import { useRequestMutation } from "../../../request";
export const useRequestFlagRecord = function() {
    let { ...props } = arguments.length > 0 && arguments[0] !== void 0 ? arguments[0] : {};
    return {
        mutationPost: useRequestMutation({
            ...props,
            mutationFnRequestProps: (data)=>({
                    body: JSON.stringify(data),
                    urlBackendPath: "/moderation/flag-record"
                })
        })
    };
};
