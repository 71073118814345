import { z } from "zod";
import { basePrayerInCollectionSchema } from "../prayer";
import { baseCollectionSchema } from "./baseCollectionSchema";
export const collectionSchema = baseCollectionSchema.extend({
    is_completed: z.boolean(),
    prayers: z.array(basePrayerInCollectionSchema.omit({
        guides: true,
        order: true
    })),
    users_count: z.number().nullable().optional(),
    place_in_line: z.number().nullable().optional(),
    has_joined: z.boolean().nullable().optional()
});
export const defaultCollectionSchema = collectionSchema.omit({
    type: true
}).extend({
    type: z.literal("default")
});
export const challengeSchema = collectionSchema.omit({
    type: true,
    users_count: true,
    place_in_line: true,
    has_joined: true
}).extend({
    type: z.literal("challenge"),
    users_count: z.number().nullable(),
    place_in_line: z.number().nullable(),
    has_joined: z.boolean().nullable()
});
