import { homeSchema } from "../../../lib";
import { request } from "../../../request";
export const requestHomeTrusted = (param)=>{
    let { date } = param;
    return request({
        next: {
            tags: [
                "home"
            ],
            revalidate: 60 * 60
        },
        requestType: "trusted",
        schema: homeSchema,
        urlBackendPath: "/trusted/home/".concat(date)
    });
};
