import { z } from "zod";
import { rawBooleanlikeSchema } from "./rawBooleanlikeSchema";
import { rawLiteralsSchema } from "./rawLiteralsSchema";
import { rawNullableStringSchema } from "./rawNullableStringSchema";
import { rawNumberSchema } from "./rawNumberSchema";
import { rawStringArraySchema } from "./rawStringArraySchema";
import { rawStringSchema } from "./rawStringSchema";
import { searchItemMetaSchema } from "./searchItemMetaSchema";
export const searchItemSchema = z.object({
    languages: rawStringArraySchema,
    item_id: rawNumberSchema,
    image_url: rawStringSchema,
    deeplink: rawStringSchema,
    length: rawNullableStringSchema,
    description: rawStringSchema,
    is_music: rawBooleanlikeSchema,
    type: rawLiteralsSchema(z.literal("collection"), z.literal("navigation"), z.literal("page"), z.literal("prayer"), z.literal("radio_station"), z.literal("setting")),
    title: rawStringSchema,
    tags: rawStringArraySchema.or(rawStringSchema),
    guides: rawStringArraySchema.or(rawStringSchema),
    search_boost_value: rawNumberSchema,
    short_desc: rawStringSchema,
    is_paid: rawBooleanlikeSchema,
    _meta: searchItemMetaSchema,
    id: rawStringSchema
});
