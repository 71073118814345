/* __next_internal_client_entry_do_not_use__ useRequestProducts auto */ import { productsSchema } from "../../../lib";
import { useRequestQuery } from "../../../request";
import { keyPlans } from "./keyProducts";
export const useRequestProducts = ()=>{
    return {
        query: useRequestQuery({
            queryFnRequestProps: {
                schema: productsSchema,
                urlBackendPath: "/products/plans"
            },
            queryKey: keyPlans()
        })
    };
};
