import { articleSchema, menusSchema } from "../../../lib";
import { request } from "../../../request";
export const requestWordpressMenus = (param)=>{
    let { locale } = param;
    const signal = AbortSignal.timeout(10000);
    signal.throwIfAborted();
    return request({
        next: {
            tags: [
                "menus"
            ],
            revalidate: 60 * 60
        },
        requestType: "default",
        schema: menusSchema,
        url: "".concat(process.env.NEXT_PUBLIC_WORDPRESS_API, "/menus?locale=").concat(locale),
        signal
    });
};
export const requestWordpressArticles = (param)=>{
    let { locale } = param;
    const signal = AbortSignal.timeout(10000);
    signal.throwIfAborted();
    return request({
        next: {
            tags: [
                "articles"
            ],
            revalidate: 60 * 60
        },
        requestType: "default",
        schema: articleSchema.array(),
        url: "".concat(process.env.NEXT_PUBLIC_WORDPRESS_API, "/posts/latest?locale=").concat(locale),
        signal
    });
};
