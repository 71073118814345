/* __next_internal_client_entry_do_not_use__ useCookie auto */ import { useEffect, useState } from "react";
import { getCookie, setCookie } from "../lib";
export const useCookie = (param)=>{
    let { defaultValue = null, key, schema } = param;
    var _getCookie;
    const [state, setState] = useState((_getCookie = getCookie({
        key,
        schema
    })) !== null && _getCookie !== void 0 ? _getCookie : defaultValue);
    useEffect(()=>{
        setCookie({
            key,
            value: state
        });
    }, [
        state
    ]);
    return [
        state,
        setState
    ];
};
